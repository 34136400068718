import React from 'react'
import { PlusCircle } from 'react-feather'

const buttonNewAssignment = ({ open }) => {
  return (
    <button
      onClick={() => open()}
      className="text-center mt-3 btn btn-secondary"
      style={{
        margin: '0 auto',
        display: 'block',
        padding: '.5rem 1.2rem .5rem 1.7rem',
      }}
    >
      <PlusCircle
        size={18}
        className="align-middle me-2"
        style={{ marginLeft: '-1rem' }}
      />
      Nuevo encargo
    </button>
  )
}

export default buttonNewAssignment
