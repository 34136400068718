import React, { useEffect, useState } from 'react'
import AuthLayout from '../../layouts/Auth'
import PigeonImg from '../../components/common/PigeonImg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { baseAPI } from '../../config'
import { toast } from 'react-toastify'
import { Button, Card, CardBody, Form, FormGroup, Input } from 'reactstrap'

const ForgotPassword = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [query, setQuery] = useState('')

  useEffect(() => {
    setQuery(new URLSearchParams(location.search.replace('?', '')))
  }, [location.search])

  const forgotPassword = (e) => {
    e.preventDefault()
    const email = e.target.email.value
    const url = `${baseAPI}/reset/${email}`

    fetch(url)
      .then(async (res) => {
        if (res.status === 200) {
          toast.success(
            'Email enviado con las instrucciones para resetear la contraseña',
            { theme: 'colored' }
          )
          return navigate('/login?security=PWDResetDone')
        }

        if (res.status === 404)
          return toast.warn('El correo no existe', { theme: 'colored' })
        if (res.status === 403)
          return toast.warn('Este usuario debe iniciar sesión con Google', {
            theme: 'colored',
          })

        return toast.error('Algo ha salido mal, intentalo más tarde', {
          theme: 'colored',
        })
      })
      .catch((_err) => {
        return toast.error('Algo ha salido mal, intentalo más tarde', {
          theme: 'colored',
        })
      })
  }

  return (
    <AuthLayout>
      <div className="text-center">
        <Link to="/">
          <PigeonImg />
        </Link>
      </div>

      <Card>
        <CardBody className="text-center">
          {(!query || query.security !== 'PWDNotReset') && (
            <h1 className="h2 mt-4">Has olvidado tu contraseña?</h1>
          )}
          <p className="lead mb-3">
            Introduce tu email para resetear la contraseña.
          </p>
          <div className="m-sm-4">
            <Form onSubmit={forgotPassword}>
              <FormGroup>
                <Input
                  bsSize="lg"
                  type="email"
                  name="email"
                  placeholder="Email"
                />
              </FormGroup>
              <div className="text-center mt-4 mb-4">
                <Button color="primary" size="lg">
                  Resetear contraseña
                </Button>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </AuthLayout>
  )
}

export default ForgotPassword
