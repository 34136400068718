import React, { useState } from 'react'
import { Tooltip, Button } from 'reactstrap'

function FormFieldTooltip({ text, validationError }) {
  const [tooltipOpenResume, setTooltipOpenResume] = useState(false)
  const toggleResume = () => setTooltipOpenResume(!tooltipOpenResume)

  return (
    <div className="d-flex align-items-self-start">
      <Button
        id="TooltipResume"
        type="button"
        color={validationError ? 'danger' : 'primary'}
        outline
        style={{
          height: '31px',
          width: '31px',
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
          marginTop: '29px',
          borderColor: validationError ? '#d9534f' : '#ced4da',
        }}
      >
        ?
      </Button>
      <Tooltip
        placement="top"
        isOpen={tooltipOpenResume}
        target="TooltipResume"
        toggle={toggleResume}
      >
        {text}
      </Tooltip>
    </div>
  )
}

export default FormFieldTooltip
