/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { validateSpanishId } from 'spain-id'
import IBAN from 'iban'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { Row, Col, Button, Card, CardBody, Label } from 'reactstrap'
import {
  addUserInvoiceAccount,
  updateUserInvoiceAccount,
  deleteUserInvoiceAccount,
  resetSepaAuthURL,
} from '../../redux/actions/userInvoiceAccount'
import FormFieldTooltip from './FormFieldTooltip'
import { RefreshCw } from 'react-feather'
import SepaImg from '../../assets/images/SepaLogoES.jpg'
import moment from 'moment'

const InvoiceAccountForm = ({
  showFormActions,
  id,
  parent_action,
  loaded_data,
  onSubmit,
  close,
}) => {
  const dispatch = useDispatch()
  const { activeUser } = useSelector((state) => state.auth)
  const { user } = useSelector((state) => state.users)
  const { userInvoiceAccounts } = useSelector(
    (state) => state.userInvoiceAccount
  )

  const [invoice_account, setInvoiceAccount] = useState({})
  const [action, setAction] = useState('')
  // const [editRestricted, setEditRestricted] = useState(false)
  const [emailValidationError, setEmailValidationError] = useState(false)
  // const [editIbanRestricted, setEditIbanRestricted] = useState(false)
  // const [oldIban, setOldIban] = useState('')
  // const [oldSepa, setOldSepa] = useState('')
  const [sepaAuthUrlExpired, setSepaAuthUrlExpired] = useState(false)
  const [loading, setLoading] = useState(false)
  const [zone, setZone] = useState('')
  const [duplicatedCifMessage, setDuplicatedCifMessage] = useState('')

  useEffect(() => {
    if (loaded_data) {
      setAction('Update')
      setInvoiceAccount(loaded_data)
      setZone(loaded_data.tax_zone)
      // setEditIbanRestricted(loaded_data.sepa_payment)
      // setOldIban(loaded_data.bank_iban)
      // setOldSepa(loaded_data.sepa_payment)
    } else {
      setAction('Add')
      setInvoiceAccount({})
    }
    if (parent_action) {
      setAction(parent_action)
    }
  }, [loaded_data, parent_action])

  useEffect(() => {
    if (invoice_account?.sepa?.expires_at) {
      const authURLExpired =
        moment(invoice_account?.sepa?.expires_at).diff(moment()) < 0
      setSepaAuthUrlExpired(authURLExpired)
    }
  }, [invoice_account])

  const handleChange = (e) => {
    if (
      e.target.name === 'invoice_email' &&
      (e.target.value === '' || !e.target.value)
    ) {
      setEmailValidationError(true)
    } else {
      setEmailValidationError(false)
    }

    if (e.target.name === 'cif') {
      const foundAccount = userInvoiceAccounts.find(
        (account) => account.cif === e.target.value
      )

      if (foundAccount) {
        setDuplicatedCifMessage(
          `Para el CIF ${e.target.value} existe creada la siguiente cuenta de facturación : ${foundAccount.invoice_name} ${e.target.value}.`
        )
      } else {
        setDuplicatedCifMessage('')
      }
    }

    setInvoiceAccount({ ...invoice_account, [e.target.name]: e.target.value })
  }

  const handleResetSepaAuthURL = () => {
    if (
      invoice_account?._id &&
      !(invoice_account.mandate_active === true) &&
      !loading
    ) {
      setLoading(true)
      toast.info('Creando enlace para validación SEPA', { theme: 'colored' })

      dispatch(
        resetSepaAuthURL({
          user_invoice_account_id: String(invoice_account._id),
        })
      )

      setTimeout(() => {
        setLoading(false)
      }, 5000)
    }
  }

  const handleSubmitForm = (e, errors, values) => {
    const foundAccount = userInvoiceAccounts.find(
      (account) => account.cif === values.cif
    )
    if (foundAccount) {
      const createAccount = window.confirm(
        `¿Seguro que deseas crear una cuenta de facturación con el CIF ${values.cif}? Recuerda que existe la cuenta de facturación ${foundAccount.invoice_name} ${values.cif} con los mismos datos.`
      )
      if (!createAccount) {
        return onSubmit()
      }
    }
    if (values.bank_iban && !IBAN.isValid(values.bank_iban))
      return toast.error('El Iban es incorrecto', { theme: 'colored' })
    if (errors.length > 0) {
      return toast.error('Por favor rellene los campos correctamente', {
        theme: 'colored',
      })
    }

    // set client_id
    switch (true) {
      case activeUser.role === 'admin' && user.role === 'partner':
        values.client_id = user.partner_parent._id
        break
      case activeUser.role === 'admin' && user.role !== 'partner':
        values.client_id = user._id
        break
      case activeUser.role === 'partner':
        values.client_id = activeUser.partner_parent._id
        break
      default: // user logged in and not a partner
        values.client_id = activeUser._id
    }

    if (action === 'Add') {
      dispatch(addUserInvoiceAccount({ user_invoice_account: values }))
    } else if (action === 'Update') {
      dispatch(updateUserInvoiceAccount({ user_invoice_account: values }))
    } else if (action === 'Delete') {
      dispatch(
        deleteUserInvoiceAccount({
          user_invoice_account_id: values.user_invoice_account_id,
        })
      )
    } else {
      toast.error('Acción no definida')
    }

    if (user.sepa_payment && !invoice_account?.mandate_active) {
      toast.info(
        'Debe validar su cuenta con GoCardLess para los recibos SEPA',
        { theme: 'colored' }
      )
    }
    onSubmit() //Used to hide form
  }

  return (
    <>
      <AvForm onSubmit={handleSubmitForm} id={id}>
        {!invoice_account._id ? (
          <h4>Añadir datos de facturación</h4>
        ) : (
          <h4>Actualizar datos de facturación</h4>
        )}
        <Row>
          {invoice_account._id && (
            <Col sm="6" md="4">
              <AvField
                name="user_invoice_account_id"
                label="Id facturación"
                type="text"
                value={invoice_account._id || ''}
                readOnly={true}
              />
            </Col>
          )}

          {invoice_account?._id &&
            user.sepa_payment &&
            !invoice_account?.mandate_active && (
              <>
                {invoice_account?.sepa?.authorization_url &&
                !sepaAuthUrlExpired ? (
                  <>
                    <Col sm="12" md="12" className="text-center">
                      <Card
                        style={{
                          border: '1px solid #42A4FF',
                        }}
                        className="text-left mt-2"
                      >
                        <CardBody className="text-left">
                          <h4 className="mb-3">
                            ¡Completa tu perfil SEPA ahora!
                          </h4>
                          <p>
                            Para facilitar el proceso, hemos preparado dos vías
                            fáciles para que puedas autorizar el pago mediante
                            SEPA (domiciliación bancaria):
                          </p>
                          <ul>
                            <li>
                              Haz clic en
                              <a
                                className="h5 text-right p-1 text-secondary"
                                href={
                                  invoice_account?.sepa?.authorization_url ||
                                  '#'
                                }
                                rel="noopener noreferrer"
                              >
                                este enlace
                              </a>
                              que encontrarás a continuación.
                            </li>
                            <li>
                              Utiliza el enlace que hemos enviado a tu correo
                              electrónico.
                            </li>
                          </ul>
                          <p>
                            <b className="d-block">
                              ¿No eres la persona autorizada para realizar esta
                              acción?
                            </b>
                            No te preocupes, puedes reenviar el correo a
                            cualquier miembro de tu equipo que esté habilitado
                            para hacerlo.
                          </p>
                          <p>
                            Tras rellenar el formulario SEPA, el proceso de
                            validación podría tomar algunos minutos. Si es
                            necesario, no dudes en actualizar la página para
                            verificar que el mandato SEPA se ha completado
                            satisfactoriamente.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col sm="6" md="6" className="text-center offset-md-2">
                      <Button
                        className="h5 text-right pointer p-2"
                        onClick={!loading && handleResetSepaAuthURL}
                        disabled={loading}
                        outline
                        color="success"
                      >
                        <RefreshCw size="20" color="green" className="me-2" />
                        Iniciar la configuración y validación SEPA
                      </Button>
                    </Col>
                  </>
                )}
              </>
            )}

          {invoice_account?._id &&
            invoice_account?.mandate_active &&
            ['admin', 'company'].includes(user.role) &&
            user.sepa_payment && (
              <Col sm="6" md="6" className="text-right offset-md-2">
                <div className="text-center">
                  {activeUser.role === 'admin' && (
                    <span className="d-block mb-2 text-danger">
                      {invoice_account?.sepa?.mandate_id ??
                        '⚠️ Falta el ID del mandato'}
                    </span>
                  )}
                  <img
                    src={SepaImg}
                    alt="Sepa logo"
                    className="img-fluid"
                    width="132"
                    height="132"
                  />
                </div>
              </Col>
            )}
        </Row>
        <Row className="mt-4">
          <Col xs="12" sm="4">
            <AvField
              required
              name="tax_zone"
              label="Zona Impuestos"
              type="select"
              errorMessage="Seleccione una opción"
              value={invoice_account.tax_zone || zone}
              onChange={(e) => setZone(e.target.value)}
              // readOnly={editRestricted && (invoice_account.tax_zone || zone)}
              // disabled={editRestricted && (invoice_account.tax_zone || zone)}
            >
              <option>Seleccionar zona</option>
              <option value={'spain'}>España (Península y Baleares)</option>
              <option value={'spain_ccm'}>
                España (Canarias, Ceuta y Melilla)
              </option>
              <option value={'europe'}>Europa</option>
              <option value={'others'}>Resto del mundo</option>
            </AvField>
          </Col>
          <Col xs="12" sm="4">
            <AvField
              required
              name="cif"
              label="CIF"
              type="text"
              errorMessage="Introduzca el CIF"
              validate={{
                async: (v) =>
                  zone?.includes('spain') ? validateSpanishId(v) : true,
              }}
              value={invoice_account.cif || ''}
              onChange={handleChange}
              // readOnly={editRestricted}
            />
          </Col>
          <Col xs="12" sm="4">
            <AvField
              required
              name="invoice_name"
              label="Nombre / Empresa"
              type="text"
              errorMessage="Introduzca el nombre / empresa"
              value={invoice_account.invoice_name || ''}
              onChange={handleChange}
              // readOnly={editRestricted}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="4">
            <div className="d-flex">
              <div className="p-0 flex-fill">
                <AvField
                  required
                  name="invoice_email"
                  label="Email de facturación "
                  type="email"
                  errorMessage="Introduzca un email válido donde recibir las facturas"
                  value={invoice_account.invoice_email || ''}
                  onChange={handleChange}
                  onBlur={handleChange}
                  style={{
                    borderRight: 'none',
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                  }}
                />
              </div>
              <FormFieldTooltip
                text="Indique el email donde quiere recibir las facturas"
                validationError={emailValidationError}
              />
            </div>
          </Col>
          <Col xs="12" sm="6">
            <div className="d-flex">
              <div className="p-0 flex-fill">
                <AvField
                  name="notification_emails"
                  label="Email/s para notificaciones"
                  type="text"
                  value={invoice_account.notification_emails || ''}
                  onChange={handleChange}
                  onBlur={handleChange}
                  style={{
                    borderRight: 'none',
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                  }}
                />
              </div>
              <FormFieldTooltip text="Indique el/los email/s alternativos donde recibir notificaciones relacionadas con la facturación, separados por una coma" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="6" md="4">
            <AvField
              required
              name="invoice_address"
              label="Dirección"
              type="text"
              errorMessage="Introduzca la dirección"
              value={invoice_account.invoice_address || ''}
              onChange={handleChange}
            />
          </Col>
          <Col xs="12" sm="6" md="4">
            <AvField
              required
              name="invoice_cp"
              label="Código postal"
              type="text"
              errorMessage="Introduzca el código postal"
              value={invoice_account.invoice_cp || ''}
              onChange={handleChange}
            />
          </Col>
          <Col xs="12" sm="6" md="4">
            <AvField
              required
              name="invoice_city"
              label="Población"
              type="text"
              errorMessage="Introduzca la población"
              value={invoice_account.invoice_city || ''}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <AvField
              name="notes"
              label="Notas"
              type="text"
              value={invoice_account.notes || ''}
              onChange={handleChange}
              placeholder="Texto libre, aparecerá en la factura"
            />
          </Col>
        </Row>
        <Row>
          {loaded_data && (
            <>
              <Col>
                <Label>Cuenta por defecto</Label>
                <AvField
                  name="default"
                  label="Cuenta por defecto"
                  type="checkbox"
                  checked={!!invoice_account.default}
                  onChange={handleChange}
                />
              </Col>
              <Col>
                <Label>Cuenta Activa</Label>
                <AvField
                  name="active"
                  label="Cuenta Activa"
                  type="checkbox"
                  checked={!!invoice_account.active}
                  onChange={handleChange}
                />
              </Col>
            </>
          )}
        </Row>

        <p className="text-danger">{duplicatedCifMessage}</p>

        {showFormActions && (
          <>
            <hr />
            <div className="text-center d-flex justify-content-between">
              {loaded_data ? (
                <Button
                  color="primary"
                  size="lg"
                  type="submit"
                  onClick={() => setAction('Update')}
                >
                  Actualizar
                </Button>
              ) : (
                <Button
                  color="primary"
                  size="lg"
                  type="submit"
                  onClick={() => setAction('Add')}
                >
                  Guardar
                </Button>
              )}
              {loaded_data && activeUser.role === 'admin' && (
                <Button
                  color="danger"
                  size="lg"
                  outline
                  type="submit"
                  onClick={() => setAction('Delete')}
                >
                  Eliminar
                </Button>
              )}
              {close && (
                <Button
                  className="ms-3"
                  color="danger"
                  size="lg"
                  outline
                  type="submit"
                  onClick={close}
                >
                  Salir
                </Button>
              )}
            </div>
          </>
        )}
      </AvForm>
    </>
  )
}

export default InvoiceAccountForm
