const FreshdeskChat = ({ activeUser }) => {
  if(activeUser && activeUser._id && activeUser.role !== 'admin'){
    window.fcWidget.init({
      token: "237f1fa5-dc74-41f7-9432-cdddd16c3032",
      host: "https://wchat.eu.freshchat.com",
      locale: "es"
    })

    window.fcWidget.user.setProperties({
      user: `https://app.legalpigeon.com/panel/users/${activeUser._id}`,
      legalPigeonId: activeUser._id,
      firstName: activeUser.name,
      lastName: activeUser.last_name,
      email: activeUser.email,
      phone: activeUser.phone,
      sustituto: activeUser.is_substitute ? 'Sí' : 'No',
      role: activeUser.role,
    })
  }
}

export default FreshdeskChat