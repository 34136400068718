import errorTypes from '../../helpers/toastHelpers/errorTypes'
import { handleError } from '../../helpers/toastHelpers/handleError'
import { handleSuccess } from '../../helpers/toastHelpers/handleSuccess'
import * as types from '../constants'

const toastMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case types.CREATE_CLIENT_SUCCESS:
      handleSuccess('Usuario registrado correctamente')
      return next(action)

    case types.CREATE_CLIENT_ERROR:
      handleError(errorTypes.CREATE_USER_ERROR)
      return next(action)

    case types.CREATE_USER_ERROR:
      handleError(errorTypes.CREATE_USER_ERROR)
      return next(action)

    default:
      return next(action)
  }
}

export default toastMiddleware
