import { RSAA } from 'redux-api-middleware'
import { baseAPI } from '../../config'
import * as types from '../constants'

export function loadUserInvoiceAccount(client_id) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/user-invoice-account/user/${String(client_id)}`,
      method: 'GET',
      types: [
        types.LOAD_USER_INVOICE_ACCOUNT_REQUEST,
        types.LOAD_USER_INVOICE_ACCOUNT_SUCCESS,
        types.LOAD_USER_INVOICE_ACCOUNT_ERROR,
      ],
    },
  }
}

export function loadExpedientCreatorInvoiceAccounts(expedient_id) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/user-invoice-account/expedient-creator/${expedient_id}`,
      method: 'GET',
      types: [
        types.LOAD_EXPEDIENT_CREATOR_INVOICE_ACCOUNT_REQUEST,
        types.LOAD_EXPEDIENT_CREATOR_INVOICE_ACCOUNT_SUCCESS,
        types.LOAD_EXPEDIENT_CREATOR_INVOICE_ACCOUNT_ERROR,
      ],
    },
  }
}

export function addUserInvoiceAccount({ user_invoice_account }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user_invoice_account),
      endpoint: `${baseAPI}/user-invoice-account`,
      method: 'POST',
      types: [
        types.ADD_USER_INVOICE_ACCOUNT_REQUEST,
        types.ADD_USER_INVOICE_ACCOUNT_SUCCESS,
        types.ADD_USER_INVOICE_ACCOUNT_ERROR,
      ],
    },
  }
}

export function resetSepaAuthURL({ user_invoice_account_id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/user-invoice-account/${user_invoice_account_id}`,
      method: 'PATCH',
      types: [
        types.RESET_SEPA_AUTH_URL_REQUEST,
        types.RESET_SEPA_AUTH_URL_SUCCESS,
        types.RESET_SEPA_AUTH_URL_ERROR,
      ],
    },
  }
}

export function updateUserInvoiceAccount({ user_invoice_account }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      endpoint: `${baseAPI}/user-invoice-account/${user_invoice_account.user_invoice_account_id}`,
      body: JSON.stringify(user_invoice_account),
      method: 'PUT',
      types: [
        types.UPDATE_USER_INVOICE_ACCOUNT_REQUEST,
        types.UPDATE_USER_INVOICE_ACCOUNT_SUCCESS,
        types.UPDATE_USER_INVOICE_ACCOUNT_ERROR,
      ],
    },
  }
}

export function deleteUserInvoiceAccount({ user_invoice_account_id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/user-invoice-account/${user_invoice_account_id}`,
      method: 'DELETE',
      types: [
        types.DELETE_USER_INVOICE_ACCOUNT_REQUEST,
        types.DELETE_USER_INVOICE_ACCOUNT_SUCCESS,
        types.DELETE_USER_INVOICE_ACCOUNT_ERROR,
      ],
    },
  }
}

export function loadProjectShortTermCreatorInvoiceAccounts({ client_id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/user-invoice-account/user/${client_id}`,
      method: 'GET',
      types: [
        types.LOAD_PROJECT_SHORT_TERM_CREATOR_INVOICE_ACCOUNT_REQUEST,
        types.LOAD_PROJECT_SHORT_TERM_CREATOR_INVOICE_ACCOUNT_SUCCESS,
        types.LOAD_PROJECT_SHORT_TERM_CREATOR_INVOICE_ACCOUNT_ERROR,
      ],
    },
  }
}

export function loadProjectLongTermCreatorInvoiceAccounts({ client_id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/user-invoice-account/user/${client_id}`,
      method: 'GET',
      types: [
        types.LOAD_PROJECT_LONG_TERM_CREATOR_INVOICE_ACCOUNT_REQUEST,
        types.LOAD_PROJECT_LONG_TERM_CREATOR_INVOICE_ACCOUNT_SUCCESS,
        types.LOAD_PROJECT_LONG_TERM_CREATOR_INVOICE_ACCOUNT_ERROR,
      ],
    },
  }
}
