import React from 'react'

const AcceptConditions = () => (
  <p>
    <small>* Al registrarte manifiestas haber leído y aceptas nuestra{' '}
      <a href='https://legalpigeon.com/politica-de-privacidad-2/' target='_blank' rel='noopener noreferrer'>Política de privacidad</a> y las{' '}
      <a href='https://legalpigeon.com/condiciones-generales-de-uso-y-contratacion/' target='_blank' rel='noopener noreferrer'>Condiciones generales</a>
    </small>
  </p>
  
)

export default AcceptConditions