import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  created: {},
}

export const capabilities = (state = defaultState, action) => {
  switch (action.type) {
    case types.CREATE_CAPABILITIES_SUCCESS:
      toast.success('Test finalizado con éxito', { theme: 'colored' })

      return {
        ...state,
        created: action.payload.expedient,
      }

    case types.CREATE_CAPABILITIES_ERROR:
      toast.error('Error al finalizar el test', { theme: 'colored' })
      return state

    default:
      return state
  }
}
