import React, { useState } from 'react'
import logoImg from '../../../assets/images/2.png' // Imagen de logo para el primer nivel
import { Link } from 'react-router-dom'
import { options } from './productsOptions'
import NewAssignmentOption from '../components/NewAssignmentOptions'

const CreateNewAssignment = ({ closeModal, setIsSecondLevel }) => {
  const [selectedOption, setSelectedOption] = useState(null)

  const handleOptionClick = (option) => {
    if (option.suboptions) {
      setSelectedOption(option)
      setIsSecondLevel(true)
    } else {
      window.location.href = option.to
    }
  }

  const handleOnClickBack = () => {
    setSelectedOption(null)
    setIsSecondLevel(false)
  }

  return (
    <>
      <div className="d-flex align-items-center bg-white">
        <div className="d-flex flex-column ms-5" style={{ width: '50%' }}>
          {selectedOption ? (
            <>
              <button
                className="btn btn-secondary mb-3"
                onClick={handleOnClickBack}
                style={{ position: 'absolute', top: '16px' }}
              >
                Atrás
              </button>
              {selectedOption.suboptions.map((suboption) => (
                <Link
                  key={suboption.title}
                  to={suboption.to}
                  onClick={() => {
                    closeModal()
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  <NewAssignmentOption
                    option={suboption}
                    handleOptionClick={handleOptionClick}
                  />
                </Link>
              ))}
            </>
          ) : (
            options.map((option) => (
              <NewAssignmentOption
                option={option}
                handleOptionClick={handleOptionClick}
                key={option.title}
              />
            ))
          )}
        </div>

        <div
          className="d-flex align-items-center justify-content-center"
          style={{ width: '50%' }}
        >
          {!selectedOption && (
            <img
              src={logoImg}
              alt="Logo"
              className="img-fluid"
              style={{
                objectFit: 'contain',
                maxHeight: '660px',
                width: '100%',
              }}
            />
          )}

          {selectedOption && selectedOption.imgSrc && (
            <img
              src={selectedOption.imgSrc}
              alt={selectedOption.title}
              className="img-fluid"
              style={{
                objectFit: 'contain',
                maxHeight: '414px',
                width: '100%',
              }}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default CreateNewAssignment
