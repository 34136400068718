import React, { useState } from 'react'
import ButtonNewAssigment from './components/button'
import { Modal } from 'reactstrap'
import CreateNewAssignment from './components/createNewAssignment'
import { Button } from 'reactstrap'

const CreateNewAssignmentModal = () => {
  const [showModal, setShowModal] = useState(false)
  const [isSecondLevel, setIsSecondLevel] = useState(false)
  const handleButtonClick = () => {
    setShowModal(true)
  }

  return (
    <div>
      <ButtonNewAssigment open={handleButtonClick}>
        Open Modal
      </ButtonNewAssigment>
      <Modal
        style={{
          height: '800px',
        }}
        isOpen={showModal}
        key={showModal}
        toggle={() => setShowModal(false)}
      >
        <div
          cssmodule={{ 'modal-title': 'w-100 ' }}
          style={{ backgroundColor: 'white' }}
        >
          <div className="d-flex justify-content-between align-items-center me-2 mt-2 ms-5">
            <div className="m-3 h2 d-inline border-0">
              {!isSecondLevel && <span>¿En que te podemos ayudar?</span>}
            </div>
            <div className="flex align-items-end justify-content-end pb-1">
              <Button
                type="button"
                color="outline-danger"
                className="border-0"
                onClick={() => setShowModal(false)}
              >
                {' x '}
              </Button>
            </div>
          </div>
        </div>
        <CreateNewAssignment
          setIsSecondLevel={setIsSecondLevel}
          closeModal={() => setShowModal(false)}
          isSecondLevel={isSecondLevel}
        />
      </Modal>
    </div>
  )
}

export default CreateNewAssignmentModal
