import { toast } from 'react-toastify'
import errorTypes from './errorTypes'

export const handleError = (errorType) => {
  let message

  if (errorTypes[errorType]) {
    message = errorTypes[errorType]
  } else if (typeof errorType === 'string') {
    message = errorType
  } else {
    message = errorTypes.GENERIC_ERROR
  }

  toast.error(message, { theme: 'colored', autoClose: 3000 })
}
