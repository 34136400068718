import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  judicialBriefs: {},
  judicialBrief: {},
}

export const judicialBrief = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_JUDICIAL_BRIEFS_SUCCESS:
      return {
        ...state,
        judicialBriefs: action.payload,
      }

    case types.GET_JUDICIAL_BRIEFS_ERROR:
      toast.error(
        'Ha ocurrido un error, cargando tus encargos si persiste contacte con el administrador',
        { theme: 'colored' }
      )
      return state

    case types.GET_JUDICIAL_BRIEF_SUCCESS:
      return {
        ...state,
        judicialBrief: action.payload.judicialBrief,
      }

    case types.GET_JUDICIAL_BRIEF_ERROR:
      toast.error(
        'Ha ocurrido un error cargando tu encargo, si persiste contacte con el administrador',
        { theme: 'colored' }
      )
      return

    case types.CREATE_JUDICIAL_BRIEF_SUCCESS:
      toast.success('Encargo judicial creado', { theme: 'colored' })

      return {
        ...state,
        created: action.payload.judicialBrief,
      }

    case types.CREATE_JUDICIAL_BRIEF_ERROR:
      toast.error(
        'Ha ocurrido un error, si persiste contacte con el administrador',
        { theme: 'colored' }
      )
      return state

    case types.UPDATE_JUDICIAL_BRIEF_SUCCESS:
      toast.success('Encargo actualizada', { theme: 'colored' })
      return {
        ...state,
        judicialBrief: action.payload.judicialBrief,
      }

    case types.UPDATE_JUDICIAL_BRIEF_ERROR:
      toast.error(
        'ERROR el encargo no ha sido actualizada correctamente contacta con el administrador',
        { theme: 'colored' }
      )
      return state

    case types.DELETE_JUDICIAL_BRIEF_SUCCESS:
      toast.success('Se ha eliminado el encargo', { theme: 'colored' })
      return state

    case types.DELETE_JUDICIAL_BRIEF_ERROR:
      toast.error(
        'Ha ocurrido un error, si persiste contacte con el administrador',
        { theme: 'colored' }
      )
      return state
    default:
      return state
  }
}
