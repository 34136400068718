import { GTM } from '../config'
import TagManager from 'react-gtm-module'

export const gtmEvent = (dataLayer) => {
  if (GTM) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push(dataLayer)
  }
}

export const gtmInit = () => {
  if (GTM) {
    TagManager.initialize({
      gtmId: GTM,
    })
  }
}
