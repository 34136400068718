import { toast } from 'react-toastify'

/**
 * Return at the start of the form if there are missing inputs on
 * user form.
 * @param {function} navigate react-router navigate function
 * @param {object} user object containing user form input values
 */
export const restartFormIfMissingValues = (navigate, user) => {
  if (
    !user.name ||
    !user.last_name ||
    !user.phone ||
    !user.email ||
    !user.password ||
    !user.role
  ) {
    navigate('/create-user-form/name')
  }
}

/**
 * Handler to update group of judicial parties from tag input form
 * of "react-select" with a limit of 5 values. (button disabled if > 5)
 * @returns {void} Updates 2 states: "user" and "inputTags".
 */
export const onChangeJudicialParty = async (
  e,
  setUser,
  setJudicialParties,
  user
) => {
  const judicialParties = e ? e.map((tag) => tag.value) : null
  setJudicialParties(judicialParties)
  setUser({ ...user, substitute_judicial_parties: judicialParties })
}

/**
 * @param {function} navigate react-router navigate function
 * @param {object} user object containing user form input values.
 * @returns
 */
export const validateCheckout = async (navigate, user) => {
  if (
    !user.substitute_judicial_parties ||
    user.substitute_judicial_parties === 'Seleccionar partido judicial'
  ) {
    return toast.error('Por favor selecciona el partido judicial', {
      theme: 'colored',
    })
  }

  const maxJudicialParties = 5

  if (user.substitute_judicial_parties.length > maxJudicialParties) {
    return toast.error('Ha sobrepasado el máximo de especialidades', {
      theme: 'colored',
    })
  }

  navigate('/create-user-form/court-number')
}
