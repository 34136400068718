import React, { useState, useEffect, useCallback } from 'react'
import PageLayout from '../layouts/Page'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { judicialParties } from '../services/judicialParties'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { loadSubstitutes } from '../redux/actions/users'
import ExpedientWizard from '../components/ExpedientWizard'
import PigeonImg from '../components/common/PigeonImg'
import action from '../mixPanelsActions'
import { gtmEvent } from '../services/gtm-handler'
import { useNavigate, useLocation } from 'react-router-dom'

const Landing = () => {
  const [searched, setSearched] = useState(false)
  const [party, setParty] = useState({})
  const [utmSource, setUtmSource] = useState(null)
  const [utmCampaign, setUtmCampaign] = useState(null)

  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const users = useSelector((state) => state.users)
  const { substitutes } = users
  const navigate = useNavigate()
  const location = useLocation()

  const searchSubstitutes = useCallback(
    (searchParams) => {
      let selectedParty = judicialParties.find(
        (p) => p.id === Number(searchParams.get('party'))
      )
      selectedParty = selectedParty
        ? { value: selectedParty.id, label: selectedParty.name }
        : {}

      setSearched(true)
      setParty(selectedParty)
      dispatch(loadSubstitutes({ party_id: selectedParty.value }))
    },
    [dispatch]
  )

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    setUtmSource(searchParams.get('utm_source'))
    setUtmCampaign(searchParams.get('utm_campaign'))

    if (searchParams.get('party')) {
      searchSubstitutes(searchParams)
    }
  }, [location.search, searchSubstitutes])

  const handleSelectChange = (selectedParty) => {
    navigate(
      `/substitute-list?utm_source=${utmSource}&utm_campaign=${utmCampaign}&party=${selectedParty.value}`
    )
  }

  const handleClick = (s, nextBaseUrl, activeUser) => {
    if (activeUser && activeUser._id) {
      const { _id } = activeUser
      action.identify(_id)
      action.track('NewHearing', {
        flow_type: 'landing page/old flow',
      })
    } else {
      action.track('CuriousNewHearing')
    }

    gtmEvent({
      ecommerce: {
        currencyCode: 'EUR',
        detail: {
          products: [
            {
              id: 'VA',
              name: 'Vista no prepagada flow antiguo',
              price: '95',
              quantity: 1,
            },
          ],
        },
      },
    })

    gtmEvent({
      event: 'begin_checkout',
      ecommerce: {
        items: [
          {
            item_name: 'Vista no prepagada flow antiguo',
            item_id: 'VA',
            price: '95',
            currency: 'EUR',
            quantity: 1,
          },
        ],
      },
    })

    const utmSourceParam = utmSource ? `utm_source=${utmSource}&` : ''
    const utmCampaignParam = utmCampaign ? `utm_campaign=${utmCampaign}&` : ''

    navigate(
      `/${nextBaseUrl}?${utmSourceParam}${utmCampaignParam}party=${party.value}&party_label=${party.label}&substitute=${s._id}&name=${s.name}`
    )
  }

  const nextBaseUrl = auth.activeUser?._id
    ? 'create-expedient'
    : 'create-client-expedient'

  if (!party) return <div />

  return (
    <PageLayout className="text-center" lightHeader>
      <h1 className="text-center mt-3 mb-4">
        Encuentra a un abogado colaborador
      </h1>
      <Card className="landing">
        <CardHeader>
          <ExpedientWizard step={1} activeUser={auth.activeUser} />
        </CardHeader>
        <CardBody>
          <p className="lead mt-4 mb-3">Buscar por partido judicial:</p>
          <Row className="mb-4 mt-3">
            <Col md="6" className="offset-md-3">
              <Select
                key={party.value}
                className="react-select-container"
                classNamePrefix="react-select"
                options={judicialParties.map((i) => ({
                  value: i.id,
                  label: i.name,
                }))}
                onChange={handleSelectChange}
                placeholder="Ex: Barcelona..."
                defaultValue={party.value ? party : null}
              />
            </Col>
          </Row>

          <section className={`pt-4 pb-4 ${searched ? '' : 'hide'}`}>
            <h4 className="blue2">Abogados disponibles:</h4>
            {substitutes.length === 0 ? (
              <p>Sin resultados</p>
            ) : (
              <div className="mt-4">
                {substitutes.map((s) => {
                  const skillsAreas =
                    s.skills.filter((sk) => !sk.value.includes('-')) || []
                  const skillsSpecialities =
                    s.skills.filter((sk) => sk.value.includes('-')) || []

                  return (
                    <Col
                      md="10"
                      className="mb-2 offset-md-1"
                      key={`subs-${s._id}`}
                    >
                      <Card className="substitute-card">
                        <div className="d-flex">
                          <h4 className="blue2">Abogado:</h4>
                          {s.substitute_score >= 5 && (
                            <h5 className="ms-auto">
                              <PigeonImg height="25" className="mb-0 me-1" />
                              Recomendado
                            </h5>
                          )}
                        </div>

                        <h4>{s.name}</h4>

                        <h4 className="blue2 mt-2">Especialidades:</h4>
                        {skillsAreas.map((ska) => {
                          const areaSpecialities = skillsSpecialities.filter(
                            (sks) => sks.value.includes(ska.value)
                          )
                          const specialities =
                            areaSpecialities.length > 0
                              ? String(
                                  areaSpecialities.map((sk) => ` ${sk.label}`)
                                )
                              : ''
                          return (
                            <div key={s._id + ska.value}>
                              <b>
                                {ska.label}
                                {areaSpecialities.length > 0 ? ':' : ''}
                              </b>
                              {specialities}
                            </div>
                          )
                        })}

                        <div className="mt-4">
                          <button
                            className="btn btn-success"
                            onClick={() =>
                              handleClick(s, nextBaseUrl, auth.activeUser)
                            }
                          >
                            Seleccionar
                          </button>
                        </div>
                      </Card>
                    </Col>
                  )
                })}
              </div>
            )}
          </section>
        </CardBody>
      </Card>
    </PageLayout>
  )
}

export default Landing
