import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Button, Card, CardBody, Container } from 'reactstrap'
import { FaRegSmileBeam } from 'react-icons/fa' // Importar icono de smile

const ThanksClient = () => {
  const navigate = useNavigate()

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!localStorage.getItem('token')) {
  //       navigate('/create-user-form/name')
  //     }
  //   }, 2000)
  // }, [navigate])

  const linkTo = '/panel/expedients'

  return (
    <Container className="vh-100 d-flex align-items-center justify-content-center">
      <Card
        className="text-center shadow-lg"
        style={{ maxWidth: '600px', borderRadius: '15px' }}
      >
        <CardBody className="p-5">
          <div className="mb-4">
            <FaRegSmileBeam size={50} color="#0098FA" />
          </div>
          <h2 style={{ lineHeight: '1.5' }} className="mb-4">
            ¡Gracias por unirte a Legal Pigeon! 🎉
          </h2>
          <p className="lead mb-4">
            Ahora puedes gestionar tus sustituciones en más de 400 partidos
            judiciales de toda España.
            <br />
            ¡Estamos encantados de tenerte con nosotros! 🤗
          </p>
          <Button
            onClick={() => navigate(linkTo)}
            color="primary"
            size="lg"
            className="px-4"
          >
            Ir a la aplicación
          </Button>
        </CardBody>
      </Card>
    </Container>
  )
}

export default ThanksClient
