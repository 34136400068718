import React, { useState, useEffect } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
import ClientForm from '../components/forms/ClientForm'
import PageLayout from '../layouts/Page'
import { useDispatch, useSelector } from 'react-redux'
import ExpedientWizard from '../components/ExpedientWizard'
import Logo from '../components/common/Logo'
import LoginForm from '../components/forms/LoginForm'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import { createClient } from '../redux/actions/auth'

const CreateClientExpedient = () => {
  const [showModal, setShowModal] = useState(false)
  const [searchParams, setSearchParams] = useState({})
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setSearchParams(params)
  }, [location.search])

  useEffect(() => {
    if (auth.activeUser._id) {
      toast.success('Bienvenido', { theme: 'colored' })
      navigate(`/create-expedient${location.search}`)
      const params = new URLSearchParams(location.search)
      setSearchParams(params)
    }
  }, [auth.activeUser, navigate, location.search])

  const newUser = (values) => {
    const user = { ...values, role: 'client' }
    dispatch(createClient({ user }))
  }

  const toggleModal = () => setShowModal(!showModal)

  return (
    <PageLayout className="text-center" lightHeader>
      <Logo
        className="mt-4 mb-4 text-center"
        width="200px"
        height="auto"
        style={{
          padding: '2rem 0',
          margin: '0 auto',
        }}
      />

      <Card className="pb-4">
        <CardHeader>
          <ExpedientWizard step={2} activeUser={auth.activeUser} />
        </CardHeader>

        <CardBody>
          <Row className="mt-3 mb-4">
            <Col sm={{ size: '4', offset: 1 }}>
              <h3 className="blue2 mb-4">Ya soy cliente</h3>
              <LoginForm />
            </Col>
            <Col sm={{ size: '4', offset: 1 }}>
              <h3 className="blue2 mb-4">Nuevo cliente</h3>
              <p>Quiero registrarme como nuevo cliente</p>
              <Button onClick={toggleModal}>Registro</Button>

              <Modal isOpen={showModal} toggle={toggleModal} className="">
                <ModalHeader toggle={toggleModal}>
                  <h2 className="mb-0">Nuevo cliente</h2>
                </ModalHeader>
                <ModalBody>
                  <ClientForm
                    onSubmit={newUser}
                    showPassword
                    showInvoice={false}
                    isNew={true}
                    searchParams={searchParams}
                  />
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </PageLayout>
  )
}

export default CreateClientExpedient
