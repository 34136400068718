import React from 'react'
import { Link } from 'react-router-dom'
import LogoLandscape from '../../assets/images/logo-landscape.png'
import { useSelector } from 'react-redux'

const LightHeader = () => {
  const activeUser = useSelector((state) => state.auth.activeUser)

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-light mb-4"
      style={{ padding: '0.2rem 1.25rem' }}
    >
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={LogoLandscape} height="38" alt="LegalPigeon" />
        </Link>
        <div className="my-1 my-lg-0">
          {activeUser && activeUser._id ? (
            <Link to="/panel">Panel de control</Link>
          ) : (
            <Link to="/login">Login</Link>
          )}
        </div>
      </div>
    </nav>
  )
}

export default LightHeader
