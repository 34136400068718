import { RSAA } from 'redux-api-middleware'
import { baseAPI } from '../../config'
import * as types from '../constants'
import { getRawDate } from '../../services'

export function createExpedient({ expedient }) {
  const token = localStorage.getItem('token')
  expedient.raw_hearing_date = getRawDate(expedient.hearing_date)
  expedient.hearing_date = undefined

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(expedient),
      endpoint: `${baseAPI}/expedients`,
      method: 'POST',
      types: [
        types.CREATE_EXPEDIENT_REQUEST,
        types.CREATE_EXPEDIENT_SUCCESS,
        types.CREATE_EXPEDIENT_ERROR,
      ],
    },
  }
}

export function createSelfExpedient({ expedient }) {
  const token = localStorage.getItem('token')
  expedient.raw_hearing_date = getRawDate(expedient.hearing_date)

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(expedient),
      endpoint: `${baseAPI}/selfexpedients`,
      method: 'POST',
      types: [
        types.CREATE_SELF_EXPEDIENT_REQUEST,
        types.CREATE_SELF_EXPEDIENT_SUCCESS,
        types.CREATE_SELF_EXPEDIENT_ERROR,
      ],
    },
  }
}

export function loadExpedients({ query }) {
  const token = localStorage.getItem('token')

  const company = query.get('company')
  if (company) query.set('company_id', JSON.parse(company).value)

  const creator = query.get('creator')
  if (creator) query.set('creator_id', JSON.parse(creator).value)

  const substitute = query.get('substitute')
  if (substitute) query.set('substitute_id', substitute)

  const partner = query.get('partner')
  if (partner) query.set('partner_id', JSON.parse(partner).value)
  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/expedients?${query.toString()}`,
      method: 'GET',
      types: [
        types.LOAD_EXPEDIENTS_REQUEST,
        types.LOAD_EXPEDIENTS_SUCCESS,
        types.LOAD_EXPEDIENTS_ERROR,
      ],
    },
  }
}

export function loadExpedient({ id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/expedients/${id}`,
      method: 'GET',
      types: [
        types.LOAD_EXPEDIENT_REQUEST,
        types.LOAD_EXPEDIENT_SUCCESS,
        types.LOAD_EXPEDIENT_ERROR,
      ],
    },
  }
}

export function resetCreatedExpedient() {
  return {
    type: types.RESET_CREATED_EXPEDIENT,
    payload: {},
  }
}

export function updateExpedient({ expedient, id }) {
  const token = localStorage.getItem('token')
  expedient.raw_hearing_date = getRawDate(expedient.hearing_date)

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(expedient),
      endpoint: `${baseAPI}/expedients/${id}`,
      method: 'PUT',
      types: [
        types.UPDATE_EXPEDIENT_REQUEST,
        types.UPDATE_EXPEDIENT_SUCCESS,
        types.UPDATE_EXPEDIENT_ERROR,
      ],
    },
  }
}

export function loadAssignedExpedients({ query }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/expedients-assigned?${query.toString()}`,
      method: 'GET',
      types: [
        types.LOAD_ASSIGNED_EXPEDIENTS_REQUEST,
        types.LOAD_ASSIGNED_EXPEDIENTS_SUCCESS,
        types.LOAD_ASSIGNED_EXPEDIENTS_ERROR,
      ],
    },
  }
}

export function updateReport({ expedient_id, report, status }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ report, status }),
      endpoint: `${baseAPI}/expedients/update-report/${expedient_id}`,
      method: 'POST',
      types: [
        types.UPDATE_REPORT_REQUEST,
        types.UPDATE_REPORT_SUCCESS,
        types.UPDATE_REPORT_ERROR,
      ],
    },
  }
}

export function loadDrafts({ query }) {
  const token = localStorage.getItem('token')

  query.set('status', 'draft')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/expedients?${query.toString()}`,
      method: 'GET',
      types: [
        types.LOAD_DRAFTS_REQUEST,
        types.LOAD_DRAFTS_SUCCESS,
        types.LOAD_DRAFTS_ERROR,
      ],
    },
  }
}

export function deleteExpedient({ id, sendDeleteEmail }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ sendDeleteEmail }),
      endpoint: `${baseAPI}/expedients/${id}`,
      method: 'DELETE',
      types: [
        types.EXPEDIENT_DELETED_REQUEST,
        types.EXPEDIENT_DELETED_SUCCESS,
        types.EXPEDIENT_DELETED_ERROR,
      ],
    },
  }
}

export function docClientReminder({ id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/expedients/${id}/docreminder`,
      method: 'POST',
      types: [
        types.EXPEDIENT_DOC_REMINDER_REQUEST,
        types.EXPEDIENT_DOC_REMINDER_SUCCESS,
        types.EXPEDIENT_DOC_REMINDER_ERROR,
      ],
    },
  }
}

export function reassingExpedient({ expedient_id, substitute_id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/expedients/${expedient_id}/reassign`,
      method: 'PUT',
      body: JSON.stringify({ substitute_id }),
      types: [
        types.REASSING_EXPEDIENT_REQUEST,
        types.REASSING_EXPEDIENT_SUCCESS,
        types.REASSING_EXPEDIENT_ERROR,
      ],
    },
  }
}

export function expedientNotification({ data }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/expedients-notifications`,
      method: 'POST',
      body: JSON.stringify({ data }),
      types: [
        types.EXPEDIENT_NOTIFICATION_REQUEST,
        types.EXPEDIENT_NOTIFICATION_SUCCESS,
        types.EXPEDIENT_NOTIFICATION_ERROR,
      ],
    },
  }
}

export function deleteExpedientFile({ type, file, expedient_id }) {
  const token = localStorage.getItem('token')
  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/expedients/${expedient_id}/deleteFile`,
      method: 'DELETE',
      body: JSON.stringify({ type, file }),
      types: [
        types.EXPEDIENT_DELETE_FILE_REQUEST,
        types.EXPEDIENT_DELETE_FILE_SUCCESS,
        types.EXPEDIENT_DELETE_FILE_ERROR,
      ],
    },
  }
}

export function deleteReportFile({ type, file, expedient_id }) {
  const token = localStorage.getItem('token')
  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/expedients/delete-report-file/${expedient_id}`,
      method: 'DELETE',
      body: JSON.stringify({ type, file }),
      types: [
        types.EXPEDIENT_DELETE_FILE_REQUEST,
        types.EXPEDIENT_DELETE_FILE_SUCCESS,
        types.EXPEDIENT_DELETE_FILE_ERROR,
      ],
    },
  }
}

export function sendSubstituteUrgentIssueExpedientReminder({
  document_type,
  document_id,
  emails,
  admin_name,
}) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        document_type,
        document_id,
        emails,
        admin_name,
      }),
      endpoint: `${baseAPI}/urgent-issue-reminder/`,
      method: 'POST',
      types: [
        types.URGENT_ISSUE_REMINDER_EXPEDIENT_REQUEST,
        types.URGENT_ISSUE_REMINDER_EXPEDIENT_SUCCESS,
        types.URGENT_ISSUE_REMINDER_EXPEDIENT_ERROR,
      ],
    },
  }
}
