import React, { useState, useEffect } from 'react'
import { Button, CardBody, Label } from 'reactstrap'
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createClientForm, loadSubstitutes } from '../../redux/actions/users'
import { toast } from 'react-toastify'
import Loading from '../../components/common/Loading'
import { gtmEvent } from '../../services/gtm-handler'

const PayingInfo = ({ user, expedient, setExpedient }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [substituteNumber, setSubstituteNumber] = useState(0)
  const [substitute_id, setSubstitute_id] = useState(false)
  // const [password, setPassword] = useState(
  //   'Aa1_' + Math.random().toString(36).substring(2, 15)
  // )

  const password = 'Aa1_' + Math.random().toString(36).substring(2, 15)

  const substitutes = useSelector((state) => state.users.substitutes)
  const createdUser = useSelector((state) => state.auth.activeUser)

  useEffect(() => {
    setSubstituteNumber(Math.floor(Math.random() * (12 - 3)) + 3)
    dispatch(loadSubstitutes({ party_id: expedient.judicial_party }))
  }, [dispatch, expedient.judicial_party])

  useEffect(() => {
    if (substitutes.length > 0) {
      const substitute_id = substitutes[0]._id
      setSubstitute_id(substitute_id)
    }
  }, [substitutes])

  useEffect(() => {
    if (createdUser && createdUser._id) {
      setExpedient((prevExpedient) => ({
        ...prevExpedient,
        substitute_id,
        status: 'awaiting_acceptation',
        creator_id: createdUser._id,
      }))

      gtmEvent({
        event: 'checkout',
        ecommerce: {
          currencyCode: 'EUR',
          checkout: {
            actionField: { step: 1 },
            products: [
              {
                id: 'VP-L',
                name: 'Vista prepagada form landing',
                price: '95',
                quantity: 1,
              },
            ],
          },
        },
      })

      gtmEvent({
        event: 'begin_checkout',
        ecommerce: {
          items: [
            {
              item_name: 'Vista prepagada form landing', // Name o ID requerido.
              item_id: 'VP-L',
              price: '95',
              currency: 'EUR',
              quantity: 1,
            },
          ],
        },
      })

      navigate('/create-expedient-form/paying-process')
    }
  }, [createdUser, substitute_id, navigate, setExpedient])

  useEffect(() => {
    if (
      !expedient.judicial_party ||
      expedient.hearing_type < 0 ||
      !expedient.hearing_date ||
      !expedient.dificulty ||
      !expedient.court ||
      !expedient.resume ||
      !user.name ||
      !user.last_name
    ) {
      navigate('/create-expedient-form/name')
    }
  }, [expedient, user, navigate])

  const checkoutIntent = () => {
    const searchParams = new URLSearchParams(location.search.replace('?', ''))

    if (searchParams) {
      user['utm_source'] = searchParams.get('utm_source') || ''
      user['utm_campaign'] = searchParams.get('utm_campaign') || ''
    }

    dispatch(
      createClientForm({
        user: { ...user, password, role: 'client' },
      })
    )
  }

  const submit = (e, errors, values) => {
    if (errors.length > 0 || values.password === '') {
      return toast.error('Por favor lea y acepte las condiciones', {
        theme: 'colored',
      })
    }
    setLoading(true)
    checkoutIntent()
  }

  return (
    <CardBody>
      <Loading loading={loading} />
      <div className="d-flex justify-content-center align-items-center flex-column">
        <h2 style={{ lineHeight: '1.5' }} className="col-lg-6 text-center">
          Ya está todo listo. Tenemos a {substituteNumber} abogados sustitutos
          disponibles en {expedient.judicialPartyValue}. Date prisa porque es
          probable que no estén disponibles por mucho tiempo.
        </h2>
        <h2 style={{ lineHeight: '1.5' }} className="col-lg-6 text-center">
          Reserva ya y asegúrate que uno de ellos acuda a tu encargo.
        </h2>
        <h2 style={{ lineHeight: '1.5' }} className="col-lg-6 text-center">
          Completa la forma de pago para finalizar tu solicitud.
        </h2>
      </div>
      <AvForm onSubmit={submit}>
        <AvGroup className="mt-4 mb-3 text-center" check>
          <AvInput type="checkbox" name="checkbox" required />
          <Label check for="checkbox">
            Manifiestas haber leído y aceptas nuestra{' '}
            <a
              href="https://legalpigeon.com/politica-de-privacidad-2/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de privacidad
            </a>{' '}
            y las{' '}
            <a
              href="https://legalpigeon.com/condiciones-generales-de-uso-y-contratacion/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Condiciones generales
            </a>
          </Label>
        </AvGroup>
        <div className="d-flex flex-md-row flex-column justify-content-md-center align-items-md-center">
          <Button color="success" size="lg">
            RESERVA YA
          </Button>
        </div>
      </AvForm>
    </CardBody>
  )
}

export default PayingInfo
