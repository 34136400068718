import React from 'react'
import logoImg from '../../assets/images/logo.png'

const Logo = props => (
  <img
    src={logoImg}
    height={props.height || '80'}
    width={props.width || 'auto'}
    className={props.className}
    alt='Logo'
    style={props.style || {}}
  />
)

export default Logo