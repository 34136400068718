import {
  AWS_COGNITO_DOMAIN,
  AWS_COGNITO_CLIENT_ID,
  AWS_REDIRECT_URI_SIGNUP,
  AWS_REDIRECT_URI_LOGIN,
  AWS_REDIRECT_URI_LOGOUT,
} from '../config'

// Utiliza el objeto window.crypto para mayor compatibilidad
const sha256 = async (buffer) => {
  return await window.crypto.subtle.digest('SHA-256', buffer)
}

// Genera un string aleatorio para code_verifier y state
const generateRandomString = (length) => {
  const array = new Uint8Array(length)
  window.crypto.getRandomValues(array)
  return Array.from(array, (byte) => ('0' + byte.toString(16)).slice(-2)).join(
    ''
  )
}

// Función para codificar en Base64 URL-safe sin relleno
const base64URLEncode = (arrayBuffer) => {
  const base64String = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)))
  return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
}

export const googleSignUp = async () => {
  const state = generateRandomString(16) // Genera un state aleatorio
  const code_verifier = generateRandomString(64) // Genera un code_verifier aleatorio

  sessionStorage.setItem(`code_verifier-${state}`, code_verifier)
  // Convierte el code_verifier a un Uint8Array
  const codeVerifierUint8 = new TextEncoder().encode(code_verifier)
  // Calcula el SHA-256 del code_verifier
  const codeChallengeBuffer = await sha256(codeVerifierUint8)
  // Codifica el resultado en Base64 URL-safe
  const code_challenge = base64URLEncode(codeChallengeBuffer)

  // Construye la URL para la autorización
  const uri_awsDomain = `${AWS_COGNITO_DOMAIN}`
  const uri_idp = 'identity_provider=Google'
  const uri_state = `state=${state}`
  const uri_codeChallengeMethod = 'code_challenge_method=S256'
  const uri_codeChallenge = `code_challenge=${code_challenge}`
  const uri_responseType = 'response_type=CODE'
  const uri_clientId = `client_id=${AWS_COGNITO_CLIENT_ID}`
  const uri_scope = 'scope=email openid profile'
  const uri_redirect = `redirect_uri=${AWS_REDIRECT_URI_SIGNUP}`

  const url = `${uri_awsDomain}/oauth2/authorize?${uri_idp}&${uri_state}&${uri_codeChallengeMethod}&${uri_codeChallenge}&${uri_responseType}&${uri_clientId}&${uri_scope}&${uri_redirect}`
  setTimeout(() => {
    window.location.href = url
  }, 10)
}

export const googleLogin = async () => {
  const state = generateRandomString(16) // Genera un state aleatorio
  const code_verifier = generateRandomString(64) // Genera un code_verifier aleatorio

  sessionStorage.setItem(`code_verifier-${state}`, code_verifier)
  // Convierte el code_verifier a un Uint8Array
  const codeVerifierUint8 = new TextEncoder().encode(code_verifier)
  // Calcula el SHA-256 del code_verifier
  const codeChallengeBuffer = await sha256(codeVerifierUint8)
  // Codifica el resultado en Base64 URL-safe
  const code_challenge = base64URLEncode(codeChallengeBuffer)

  const uri_awsDomain = `${AWS_COGNITO_DOMAIN}`
  const uri_idp = 'identity_provider=Google'
  const uri_state = `state=${state}`
  const uri_codeChallengeMethod = 'code_challenge_method=S256'
  const uri_codeChallenge = `code_challenge=${code_challenge}`
  const uri_responseType = 'response_type=CODE'
  const uri_clientId = `client_id=${AWS_COGNITO_CLIENT_ID}`
  const uri_scope = 'scope=email openid profile'
  const uri_redirect = `redirect_uri=${AWS_REDIRECT_URI_LOGIN}`

  const url = `${uri_awsDomain}/oauth2/authorize?${uri_idp}&${uri_state}&${uri_codeChallengeMethod}&${uri_codeChallenge}&${uri_responseType}&${uri_clientId}&${uri_scope}&${uri_redirect}`
  window.location.href = url
}

export const googleLogout = async () => {
  const uri_awsDomain = `${AWS_COGNITO_DOMAIN}`
  const uri_clientId = `client_id=${AWS_COGNITO_CLIENT_ID}`
  const uri_redirect = `logout_uri=${AWS_REDIRECT_URI_LOGOUT}`

  const url = `${uri_awsDomain}/logout?${uri_clientId}&${uri_redirect}`
  window.location.href = url
}
