import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const styles = {
  button: {
    width: '100%',
    height: '125px',
  },
  cardContainer: {
    position: 'relative',
    width: '100%',
  },
  cardBody: {
    width: '100%',
  },
  badge: (color) => ({
    position: 'absolute',
    right: '-26px',
    top: '0px',
    width: '90px',
    transform: 'rotate(45deg)',
    backgroundColor: 'white',
    border: `2px solid ${color}`,
    borderRadius: '10px',
    padding: '3px',
    color: color,
    fontWeight: 'bold',
  }),
  icon: {
    width: '50px',
  },
  title: (show) => ({
    color: show ? 'inherit' : 'transparent',
    textAlign: 'left',
    textShadow: !show ? '0 0 20px #000' : 'none',
  }),
  content: (show) => ({
    color: show ? 'inherit' : 'transparent',
    textShadow: !show ? '0 0 20px #000' : 'none',
    textAlign: 'left',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textDecoration: 'none',
  }),
}

const NewAssignmentOption = ({ option, handleOptionClick }) => {
  return (
    <div key={option.title}>
      <button
        className={`card btn btn-pill btn-outline-primary border border-primary rounded d-flex justify-content-center align-items-center ${
          !option.show || option.disabled ? 'disabled' : ''
        }`}
        onClick={() => handleOptionClick(option)}
        style={styles.button}
      >
        <div className="d-flex align-items-center" style={styles.cardContainer}>
          {option.isNew && <h4 style={styles.badge('green')}>New!!</h4>}
          {option.isCommingSoon && (
            <h4 style={styles.badge('black')}>Coming soon!</h4>
          )}
          {option.fontAwesome && (
            <FontAwesomeIcon
              className="ml-3"
              size="2x"
              icon={option.fontAwesome}
              style={styles.icon}
            />
          )}
          <div className="card-body" style={styles.cardBody}>
            <h3 style={styles.title(option.show)}>
              <strong>{option.title}</strong>
            </h3>
            <p
              dangerouslySetInnerHTML={{ __html: option.content }}
              className="mb-0"
              style={styles.content(option.show)}
            ></p>
          </div>
        </div>
      </button>
    </div>
  )
}

export default NewAssignmentOption
