import React, { useState, Fragment } from 'react'
import { PlusCircle } from 'react-feather'
import { NavLink } from 'react-router-dom'
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap'
import judicialBriefForms from '../../services/judicialBriefForms'
import FormBuilder from './FormBuilder'

const NewJudicialBrief = (props) => {
  const [showModal, setShowModal] = useState(false)

  const defaultText = 'Seleccione el encargo'
  const [selectedTemplate, setSelectedTemplate] = useState(defaultText)

  const closeModal = () => {
    setShowModal(false)
    setSelectedTemplate(defaultText)
  }

  return (
    <Fragment>
      {props.type === 'icon' ? (
        <NavLink to="#">
          <Button
            type="button"
            size="lg"
            color="outline-secondary"
            className="m-1 border-0"
            onClick={() => setShowModal(true)}
          >
            <PlusCircle size={16} className="align-middle" />
          </Button>
        </NavLink>
      ) : (
        <div
          onClick={() => setShowModal(true)}
          className="sidebar-link text-center"
          style={{ color: 'white', background: '#2d3646' }}
        >
          <PlusCircle
            size={18}
            className="align-middle me-3"
            style={{ marginLeft: '-1rem' }}
          />
          Nuevo escrito
        </div>
      )}

      <Modal
        style={{ maxWidth: '1200px', height: '800px' }}
        isOpen={showModal}
        toggle={closeModal}
      >
        <ModalHeader cssmodule={{ 'modal-title': 'w-100 ' }} className="pb-1">
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="mb-0 h2 d-inline">
              Solicita un{' '}
              <span className="text-secondary"> escrito judicial</span>
            </div>
          </div>
        </ModalHeader>

        <ModalBody style={{ minHeight: '600px' }} className="mx-md-3 my-0">
          {selectedTemplate === defaultText && (
            <div>
              <p>
                Escritos judiciales revisados por abogados listos para presentar
                en el Juzgado en menos de 24 horas
              </p>
              <h4>Selecciona el tipo de escrito que quieres contratar</h4>
            </div>
          )}
          <div className="form-group">
            <select
              onChange={(e) => setSelectedTemplate(e.target.value)}
              className="is-untouched is-pristine av-invalid form-control"
              style={{ width: 'auto' }}
            >
              <option>{defaultText}</option>
              {judicialBriefForms.map((f) => {
                return (
                  <option value={f.id} key={f.id}>
                    {f.text}
                  </option>
                )
              })}
            </select>
          </div>

          <FormBuilder
            selectedTemplate={selectedTemplate}
            defaultText={defaultText}
            closeModal={closeModal}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default NewJudicialBrief
