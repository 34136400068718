const demandaDesahucio = {
  "id": "demanda_desahucio_falta_de_pago",
  "text": "Demanda de desahucio por falta de pago",
  "price": 65,
  "cost": 40,
  "data": [
    {
      "id": "demandantes",
      "text": "Demantantes",
      "type": "array",
      "required": true,
      "attributes": [
        {
          "id": "nombre",
          "text": "Nombre",
          "type": "text",
          "required": true
        },
        {
          "id": "nif_dni",
          "text": "NIF/DNI",
          "type": "text",
          "required": true
        },
        {
          "id": "domicilio",
          "text": "Domicilio",
          "type": "text",
          "required": true
        }
      ]
    },
    {
      "id": "demandados",
      "text": "Demandados",
      "type": "array",
      "required": true,
      "attributes": [
        {
          "id": "nombre",
          "text": "Nombre",
          "type": "text",
          "required": true
        },
        {
          "id": "nif_dni",
          "text": "NIF/DNI",
          "type": "text",
          "required": true
        },
        {
          "id": "domicilio",
          "text": "Domicilio",
          "type": "text",
          "required": true
        }
      ]
    },
    {
      "id": "abogado",
      "text": "Abogado que firma la demanda",
      "type": "text",
      "required": true
    },
    {
      "id": "colegiado_abogado",
      "text": "Número de colegiado abogado",
      "type": "text",
      "required": true
    },
    {
      "id": "procurador",
      "text": "Procurador que firma la demanda",
      "type": "text",
      "required": true
    },
    {
      "id": "juzgado",
      "text": "Juzgado donde se presenta la demanda",
      "type": "text",
      "required": true
    },
    {
      "id": "poder_para_pleito",
      "text": "Tipo de poder de representación del procurador",
      "type": "select",
      "required": true,
      "options" :['poder para pleitos','apud acta que se realizará en el juzgado una vez requeridos al efecto']
    },
    {
      "id": "fecha_contrato",
      "text": "Fecha contrato arrendamiento",
      "type": "date",
      "required": true
    },
    {
      "id": "domicilio_arrendamiento",
      "text": "Domicilio de la finca arrendada (calle, número, piso, puerta, población)",
      "type": "text",
      "required": true
    },
    {
      "id": "duracion_contrato_años",
      "text": "Duración contrato arrendamiento (años)",
      "type": "number",
      "required": true
    },
    {
      "id": "importe_renta_mensual",
      "text": "Importe renta mensual acordada",
      "type": "number",
      "required": true
    },
    {
      "id": "prorrogas_pacatadas",
      "text": "Prorrogas pactadas (años)",
      "type": "number",
      "required": true
    },
    {
      "id": "deuda_rentas",
      "text": "Importe total rentas impagadas y otros conceptos",
      "type": "number",
      "required": true
    },
    {
      "id": "numero_rentas_impagadas",
      "text": "¿Cuántas mensualidades de rentas impagadas?",
      "type": "number",
      "required": true
    },
    {
      "id": "contrato_arrendamiento",
      "text": "Contrato de arrendamiento",
      "type": "upload",
      "required": true
    },
    {
      "id": "otros_documentos",
      "text": "Otros documentos que consideres oportunos",
      "type": "upload",
      "required": false
    },
    {
      "id": "burofax_previo",
      "text": "Burofax previo",
      "type": "upload",
      "required": false
    },
    {
      "id": "notas",
      "text": "Notas para nuestro abogado",
      "type": "textarea",
      "required": true
    }
  ]
}

export default demandaDesahucio