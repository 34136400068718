import * as types from '../constants'

const defaultState = {}

export const publicApi = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_API_KEY_SUCCESS:
      return {
        ...state,
        apiKey: action.payload.apiKey,
        createdAt: action.payload.createdAt,
      }
    case types.GET_LAST_API_KEY_DATE_SUCCESS:
      return {
        ...state,
        createdAt: action.payload.createdAt,
      }
    case types.RESET_API_KEY:
      return {
        ...state,
        apiKey: null,
      }
    case types.DELETE_LAST_API_KEY_DATE_SUCCESS:
      return defaultState
    default:
      return state
  }
}
