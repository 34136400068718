import React from 'react'
import moment from 'moment'
import {
  HelpCircle,
  Clock,
  Check,
  ArrowRight,
  XCircle,
  Lock,
} from 'react-feather'
import { toast } from 'react-toastify'
import { baseAPI } from '../../src/config'
import { project_st_type } from './substituteSkills'
import { judicialParties } from './judicialParties'
import { hearingDefaultList } from './hearingType'
import { loadDrafts } from '../redux/actions/expedient'
import { loadProcuraDrafts } from '../redux/actions/procura'
import { loadOnCallDrafts } from '../redux/actions/projectShortTerm'
// import { loadInHouseDrafts } from '../redux/actions/inHouse'

export const isHearingUrgent = (date, isProcura = false) => {
  const days = 1
  const time = isProcura ? 8 : 15

  return (
    (moment(date).format('DD/MM/YYYY') ===
      moment().add(days, 'days').format('DD/MM/YYYY') &&
      moment().format('HH') * 1 >= time) ||
    (['lunes', 'Monday'].includes(moment(date).format('dddd')) &&
      ['viernes', 'Friday', 'sábado', 'Saturday', 'domingo', 'Sunday'].includes(
        moment().format('dddd')
      ) &&
      moment(date).isSame(moment().startOf('isoWeek').add(1, 'weeks'), 'day') &&
      moment().format('HH') * 1 >= time)
  )
}

export const expedientStatusName = (status) => {
  let name = ''

  switch (status) {
    // expedient statuses
    case 'awaiting_acceptation':
      name = 'Pendiente de aceptación'
      break
    case 'project_review':
      name = 'Revisando documentación'
      break
    case 'awaiting_documentation':
      name = 'Esperando documentación'
      break
    case 'in_progress':
      name = 'En proceso'
      break
    case 'delivered':
      name = 'Entregado'
      break
    case 'to_review':
      name = 'En revisión'
      break
    case 'reviewed':
      name = 'Revisado'
      break
    case 'celebration_pending':
      name = 'Pendiente de celebración'
      break
    case 'celebrated':
      name = 'Celebrada'
      break
    case 'posponed':
      name = 'Pospuesto'
      break
    case 'suspended':
      name = 'Suspendida'
      break
    case 'canceled':
      name = 'Cancelado'
      break
    case 'closed':
      name = 'Finalizado'
      break

    // invoice statuses 'proforma', 'creada', 'pagada, cobrada'
    case 'proforma':
      name = 'Proforma'
      break
    case 'creada':
      name = 'Creada'
      break
    case 'pagada':
      name = 'Pagada'
      break
    case 'cobrada':
      name = 'cobrada'
      break
    default:
      break
  }

  return name
}

export const invoiceStatusName = (status) => {
  let name = ''

  switch (status) {
    // invoice statuses 'proforma', 'creada', 'pagada, cobrada'
    case 'proforma':
      name = 'Proforma'
      break
    case 'creada':
      name = 'Creada'
      break
    case 'pagada':
      name = 'Pagada'
      break
    case 'cobrada':
      name = 'Cobrada'
      break
    default:
      break
  }

  return name
}

export const expedientStatusBadge = (status) => {
  let badge = ''

  switch (status) {
    case 'awaiting_acceptation':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 px-2 py-1 bg-secondary">
          Esperando aceptación
        </span>
      )
      break
    case 'awaiting_sepa':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 px-2 py-1 bg-warning">
          Validando pago
        </span>
      )
      break
    case 'payment_rejected_sepa':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 px-2 py-1 bg-danger">
          Pago rechazado
        </span>
      )
      break
    case 'celebration_pending':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-primary px-2 py-1">
          Pendiente celebración
        </span>
      )
      break
    case 'celebrated':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-success px-2 py-1">
          Celebrada
        </span>
      )
      break
    case 'posponed':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-primary px-2 py-1">
          Pospuesta
        </span>
      )
      break
    case 'suspended':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-info px-2 py-1">
          Suspendida
        </span>
      )
      break
    case 'canceled':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-danger px-2 py-1">
          Cancelada
        </span>
      )
      break
    case 'proforma':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-info px-2 py-1">
          Proforma
        </span>
      )
      break
    case 'creada':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-success px-2 py-1">
          Creada
        </span>
      )
      break
    case 'pagada':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-success px-2 py-1">
          Pagada
        </span>
      )
      break
    case 'pendiente de creación':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-primary px-2 py-1">
          Pendiente creación
        </span>
      )
      break
    case 'valido':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-success px-2 py-1">
          Válido
        </span>
      )
      break
    case 'invalido':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-danger px-2 py-1">
          Inválido
        </span>
      )
      break
    default:
      break
  }

  return badge
}

export const projectStatusBadge = (status) => {
  let badge = ''

  switch (status) {
    case 'awaiting_documentation':
      badge = (
        <span
          className="badge rounded-pill me-1 mb-1 px-2 py-1"
          style={{ backgroundColor: '#f75e25', color: '#ffffff' }}
        >
          Documentación pendiente
        </span>
      )
      break
    case 'project_review':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-primary px-2 py-1">
          Revisando documentación
        </span>
      )
      break

    case 'in_progress':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-warning px-2 py-1">
          En proceso
        </span>
      )
      break

    case 'delivered':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-success px-2 py-1">
          Entregado
        </span>
      )
      break

    case 'to_review':
      badge = (
        <span
          className="badge rounded-pill me-1 mb-1 px-2 py-1"
          style={{ backgroundColor: '#f75e25', color: '#ffffff' }}
        >
          En revisión
        </span>
      )
      break

    case 'reviewed':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-success px-2 py-1">
          Revisión entregada
        </span>
      )
      break
    case 'closed':
      badge = (
        <span
          className="badge rounded-pill me-1 mb-1 px-2 py-1"
          style={{ backgroundColor: '#a4a4a4', color: '#ffffff' }}
        >
          Finalizado
        </span>
      )
      break

    case 'awaiting_acceptation':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-secondary px-2 py-1">
          Esperando aceptación
        </span>
      )
      break

    case 'awaiting_assignation':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-secondary px-2 py-1">
          Esperando asignación
        </span>
      )
      break

    case 'assigned':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-success px-2 py-1">
          Asignado
        </span>
      )
      break

    case 'finished':
      badge = (
        <span
          className="badge rounded-pill me-1 mb-1 px-2 py-1"
          style={{ backgroundColor: '#a4a4a4', color: '#ffffff' }}
        >
          Finalizado
        </span>
      )
      break
    case 'cancelled':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-warning px-2 py-1">
          Cancelado
        </span>
      )
      break
    default:
      break
  }

  return badge
}

export const invoiceStatusBadge = (status) => {
  let badge = ''

  switch (status) {
    case 'proforma':
      badge = (
        <span
          className="badge bg-primary rounded-pill me-1 mb-1 px-2 py-1"
          style={{ padding: '4px 10px' }}
        >
          Proforma
        </span>
      )
      break
    case 'creada':
      badge = (
        <span
          className="badge bg-secondary rounded-pill me-1 mb-1 px-2 py-1"
          style={{ padding: '4px 10px' }}
        >
          Creada
        </span>
      )
      break
    case 'pagada':
      badge = (
        <span
          className="badge bg-success rounded-pill me-1 mb-1 px-2 py-1"
          style={{ padding: '4px 10px' }}
        >
          Pagada
        </span>
      )
      break
    case 'cobrada':
      badge = (
        <span
          className="badge bg-success rounded-pill me-1 mb-1 px-2 py-1"
          style={{ padding: '4px 10px' }}
        >
          Cobrada
        </span>
      )
      break
    case 'pendiente de creación':
      badge = (
        <span
          className="badge bg-primary rounded-pill me-1 mb-1 px-2 py-1"
          style={{ padding: '4px 10px' }}
        >
          Pendiente creación
        </span>
      )
      break
    default:
      break
  }

  return badge
}

export const sepaStatusBadge = (status) => {
  let badge = ''

  switch (status) {
    case 'pending':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-primary px-2 py-1">
          Pendiente
        </span>
      )
      break
    case 'created':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-secondary px-2 py-1">
          Creado
        </span>
      )
      break
    case 'late_failure_settled':
    case 'chargeback_settled':
    case 'surcharge_fee_debited':
    case 'failed':
    case 'chargeback':
    case 'cancelled':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-danger px-2 py-1">
          Error: {status}
        </span>
      )
      break
    case 'paid_out':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-success px-2 py-1">
          Cobrada
        </span>
      )
      break
    default:
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-secondary px-2 py-1">
          {status}
        </span>
      )
      break
  }

  return badge
}

export const expedientStatusBadgeIcon = (status) => {
  let badge = ''

  switch (status) {
    case 'awaiting_acceptation':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-secondary px-2 py-1">
          <HelpCircle />
        </span>
      )
      break
    case 'awaiting_sepa':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-warning px-2 py-1">
          Validando pago
        </span>
      )
      break
    case 'payment_rejected_sepa':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-danger px-2 py-1">
          Pago rechazado
        </span>
      )
      break
    case 'celebration_pending':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-primary px-2 py-1">
          <Clock />
        </span>
      )
      break
    case 'celebrated':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-success px-2 py-1">
          <Check />
        </span>
      )
      break
    case 'posponed':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-primary px-2 py-1">
          <ArrowRight />
        </span>
      )
      break
    case 'suspended':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-info px-2 py-1">
          <XCircle />
        </span>
      )
      break
    case 'canceled':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-danger px-2 py-1">
          <XCircle />
        </span>
      )
      break
    case 'closed':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-success px-2 py-1">
          <Lock />
        </span>
      )
      break
    case 'proforma':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-info px-2 py-1">
          Proforma
        </span>
      )
      break
    case 'creada':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-success px-2 py-1">
          Creada
        </span>
      )
      break
    case 'pagada':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-success px-2 py-1">
          Pagada
        </span>
      )
      break
    case 'pendiente de creación':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-primary px-2 py-1">
          Pendiente de creación
        </span>
      )
      break
    case 'valido':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-success px-2 py-1">
          Válido
        </span>
      )
      break
    case 'invalido':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-danger px-2 py-1">
          Inválido
        </span>
      )
      break
    default:
      break
  }

  return badge
}

export const briefStatusBadge = (status) => {
  let badge = ''

  switch (status) {
    case 'review':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-secondary px-2 py-1">
          En revisión
        </span>
      )
      break
    case 'in_progress':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-primary px-2 py-1">
          En desarrollo
        </span>
      )
      break
    case 'completed':
      badge = (
        <span className="badge rounded-pill me-1 mb-1 bg-success px-2 py-1">
          Completado
        </span>
      )
      break
    default:
      break
  }

  return badge
}

export const getRawDate = (date) => {
  const y = moment(date).year()
  const month = moment(date).format('MM')
  const day = moment(date).format('DD')

  const h = moment(date).format('HH')
  const m = moment(date).format('mm')
  const s = moment(date).format('ss')

  const raw = `${y}-${month}-${day}T${h}:${m}:${s}`
  return raw
}

export const getRawLocalDate = (raw_hearing_date) => {
  const raw_date = moment.utc(raw_hearing_date)

  const date = new Date(
    raw_date.format('YYYY'),
    raw_date.format('MM') - 1,
    raw_date.format('DD')
  )

  date.setHours(raw_date.format('HH'))
  date.setMinutes(raw_date.format('mm'))
  date.setSeconds(raw_date.format('ss'))

  return date
}

export const roundToTwoDecimals = (value) => Math.round(value * 100) / 100

export const getProfileImage = async (image, showToast = false) => {
  if (image.status === 'deleted') {
    return
  }
  if (image && image.status !== 'deleted') {
    try {
      const response = await fetch(`${baseAPI}/get-signed-image/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(image),
      })
      const res = await response.json()
      return res
    } catch (err) {
      showToast &&
        toast.error('Ha ocurrido un error cargando la imagen', {
          theme: 'colored',
        })
      return null
    }
  }
  showToast &&
    toast.error('Ha ocurrido un error cargando la imagen', { theme: 'colored' })
  return null
}

export const isOlderThanXdays = (date, days) => {
  const today = moment(new Date().setUTCSeconds(30))
  const differenceOfDays = today.diff(date, 'days')
  return differenceOfDays >= days
}

const getBadgeColor = (counter) => {
  if (counter <= 1) return 'danger'
  if (counter <= 3) return 'warning'
  return 'success'
}

export const CounterBadge = React.memo(({ counter }) => (
  <span className={`badge rounded-pill me-1 mb-1 bg-${getBadgeColor(counter)}`}>
    {counter}
  </span>
))

export const renderCreatorField = (
  document,
  creatorIsPopulated,
  activeUser,
  field
) => {
  if (activeUser?.role === 'admin') {
    return creatorIsPopulated ? (
      <a
        href={`${field === 'email' ? 'mailto:' : 'tel:'}${
          document?.creator_id[field]
        }`}
      >
        {document?.creator_id[field]}
      </a>
    ) : (
      '***'
    )
  }
  return isOlderThanXdays(document?.hearing_date ?? document?.deadline, 10) &&
    (activeUser?.is_substitute || activeUser?.is_procurador) &&
    activeUser?._id === document?.substitute_id._id ? (
    '***'
  ) : creatorIsPopulated ? (
    <a
      href={`${field === 'email' ? 'mailto:' : 'tel:'}${
        document?.creator_id[field]
      }`}
    >
      {document?.creator_id[field]}
    </a>
  ) : (
    '***'
  )
}

export const renderSubstituteField = (
  document,
  substituteIsPopulated,
  activeUser,
  field
) => {
  if (activeUser?.role === 'admin') {
    return substituteIsPopulated ? (
      <a
        href={`${field === 'email' ? 'mailto:' : 'tel:'}${
          document?.substitute_id[field]
        }`}
      >
        {document?.substitute_id[field]}
      </a>
    ) : (
      '***'
    )
  }
  return isOlderThanXdays(document?.hearing_date ?? document?.deadline, 10) &&
    ['client', 'company', 'partner'].includes(activeUser?.role) ? (
    '***'
  ) : substituteIsPopulated ? (
    <a
      href={`${field === 'email' ? 'mailto:' : 'tel:'}${
        document?.substitute_id[field]
      }`}
    >
      {document?.substitute_id[field]}
    </a>
  ) : (
    '***'
  )
}

export const getDocType = ({ projectst }) => {
  const { skill, project_type, doc_type } = projectst

  const projectType =
    skill && project_st_type[skill].find((pt) => pt.id === project_type)
  const docType = projectType?.doc_type.find((doc) => doc.id === doc_type)
  return docType
}

export const calcBudget = ({ experience, work_type, journey_type, weeks }) => {
  let precioBase = 600 // Precio base para junior, teletrabajo, 1 semana
  // Ajuste por experiencia
  switch (experience) {
    case 'mid-senior':
      precioBase *= 1.3
      break
    case 'senior':
      precioBase *= 1.6
      break
    default:
      break
  }
  // Ajuste por modalidad de trabajo
  switch (work_type) {
    case 'Presencial':
      precioBase *= 1.1
      break
    case 'hibrido':
      precioBase *= 1.05
      break
    default:
      break
  }
  switch (journey_type) {
    case 'Media jornada':
      precioBase *= 0.5
      break
    case 'Jornada completa':
      precioBase *= 1.0
      break
    default:
      break
  }

  return precioBase * weeks // Calcula el precio total basado en la duración en semanas
}

export const resumePlaceholders = [
  'Ejemplo: Somos demandantes en un caso de despido improcedente en una empresa manufacturera.',
  'Ejemplo: Somos demandados en una reclamación de daños y perjuicios por accidente de tráfico.',
  'Ejemplo: Nulidad contrato por usura. Defendemos a la parte demandada o demandante.',
  'Ejemplo: Proceso por fraude fiscal y evasión de impuestos.',
  'Ejemplo: Defendemos al perjudicado en un juicio por delitos contra la salud pública',
  'Ejemplo: Somos demandados en una reclamación de cantidad por impago de factura.',
  'Ejemplo: Representamos a la empresa en un caso de acoso laboral y discriminación en el trabajo.',
  'Ejemplo: Divorcio contencioso con disputa por la custodia de los hijos.',
  'Ejemplo: Somos actores en un litigio por cláusula suelo en préstamos hipotecarios.',
  'Ejemplo: Demanda por incumplimiento de contrato de compraventa de bienes inmuebles. Somos demandados.',
  'Ejemplo: Defensa en un caso de robo agravado con uso de violencia.',
  'Ejemplo: Reclamación por uso abusivo de tarjetas revolving. Somos actores. ',
  'Ejemplo: Litigio por estafa y abuso de confianza en una relación comercial.',
  'Ejemplo: Proceso de desahucio precario por ocupación ilegal de propiedad. Representamos al propietario.',
  'Ejemplo: Representamos al trabajador en una reclamación de pagos atrasados y horas extra no remuneradas.',
]

export const getJudicialPartyName = ({ document }) => {
  return judicialParties.find(
    (jp) => String(jp.id) === String(document.judicial_party)
  )?.name
}

export const getJudicialPartyNameById = (judicial_party) => {
  return judicialParties.find((jp) => String(jp.id) === String(judicial_party))
    ?.name
}

export const getHearingTypeName = ({ document }) => {
  return hearingDefaultList.find((ht) => ht.id === document.hearing_type)?.value
}

export const products = {
  expedients: {
    name: 'Expedientes',
    description: 'expedientes',
    type: 'expedients',
    mainURL: 'expedient',
    downloadLink:
      'https://drive.google.com/uc?export=download&id=1uBwbLkDfVs3_ZjP8fMPsXZvImSqs6VJ1',
    validateDrafts: `${baseAPI}/import/validation`,
    importDrafts: `${baseAPI}/import/`,
    deleteDrafts: `${baseAPI}/import/delete`,
    assignDrafts: `${baseAPI}/import/assignCreate`,
    importType: 'expedient',
    assignType: 'expedient',
    gtmItemId: 'EI',
    loadDocuments: loadDrafts,
  },
  procura: {
    name: 'Pigeon Procura',
    description: 'Pigeon Procura',
    type: 'procura',
    mainURL: 'procura',
    downloadLink:
      'https://drive.google.com/uc?export=download&id=1JIZ8NvMc2p04T6EqKWebDgl2v-rK69-X',
    validateDrafts: `${baseAPI}/import-procura/validation`,
    importDrafts: `${baseAPI}/import-procura/`,
    deleteDrafts: `${baseAPI}/import-procura/delete`,
    assignDrafts: `${baseAPI}/import-procura/assignCreate`,
    importType: 'procura',
    assignType: 'procura',
    gtmItemId: 'PROCI',
    loadDocuments: loadProcuraDrafts,
  },
  onCall: {
    name: 'Pigeon OnCall',
    description: 'Pigeon On Call',
    type: 'onCall',
    mainURL: 'projects-short-term',
    downloadLink:
      'https://drive.google.com/uc?export=download&id=1-IlG9Feofq8ao5vkhESZdBV1Kd9EUi2B',
    validateDrafts: `${baseAPI}/import-oncall/validation`,
    importDrafts: `${baseAPI}/import-oncall/`,
    deleteDrafts: `${baseAPI}/import-oncall/delete`,
    assignDrafts: `${baseAPI}/import-oncall/assignCreate`,
    importType: 'onCall',
    assignType: 'projectst',
    gtmItemId: 'OCI',
    loadDocuments: loadOnCallDrafts,
  },
  // inHouse: {
  //   name: 'Pigeon InHouse',
  //   description: 'Pigeon In House',
  //   type: 'inHouse',
  //   downloadLink: '#',
  //   'https://drive.google.com/uc?export=download&id=1uBwbLkDfVs3_ZjP8fMPsXZvImSqs6VJ1',
  //   validationImport: `${baseAPI}/import-inhouse/validation`,
  //   importDocuments: `${baseAPI}/import-inhouse/`,
  //   importType: 'inHouse',
  //   gtmItemId: 'IHI',
  //   loadDocuments: loadInHouseDrafts,
  // },
}

export const getInvoiceLink = ({ invoice }) => {
  const { expedient_id, expedient_ids, document_type, document_ids } = invoice

  const links = {
    expedient1: `/panel/expedients/${expedient_id || expedient_ids[0]}`,
    expedient2: `/panel/expedients/${document_ids[0]}`,
    procura: `/panel/procura/${document_ids[0]}`,
    onCall: `/panel/projects-short-term/${document_ids[0]}`,
    inHouse: `/panel/projects-long-term/${document_ids[0]}`,
  }

  let objectLink = {}

  if (document_type === 'procura') {
    objectLink = { linkTo: links.procura, linkDesc: document_ids[0] }
  }
  if (
    ['onCall', 'projectST', 'project-shor-term', 'oncall'].includes(
      document_type
    )
  ) {
    objectLink = { linkTo: links.onCall, linkDesc: document_ids[0] }
  }
  if (
    ['inHouse', 'projectLT', 'project-long-term', 'inhouse'].includes(
      document_type
    )
  ) {
    objectLink = { linkTo: links.inHouse, linkDesc: document_ids[0] }
  }
  if (['expedient', 'expedients', undefined].includes(document_type)) {
    if (expedient_id || expedient_ids[0]) {
      objectLink = {
        linkTo: links.expedient1,
        linkDesc: expedient_id || expedient_ids[0],
      }
    } else {
      objectLink = { linkTo: links.expedient2, linkDesc: document_ids[0] }
    }
  }
  return objectLink
}

export const userPriceProductOptions = [
  { value: 'procura', label: 'Procura' },
  { value: 'expedient', label: 'Expedientes' },
]
