import React from "react";
import { Link } from "react-router-dom";
import AuthLayout from '../../layouts/Auth'
import PigeonImg from '../../components/common/PigeonImg'

import { Button } from "reactstrap";

const Page404 = () => (
  <AuthLayout>
    <div className="text-center">
      <PigeonImg />
      <h1 className="display-1 font-weight-bold">404</h1>
      <p className="h1">Página no encontrada</p>
      <p className="h2 font-weight-normal mt-3 mb-5">
        Lo sentimos, la página voló.
      </p>
      <Link to="/">
        <Button color="primary" size="lg">
          Volver al inicio
        </Button>
      </Link>
    </div>
  </AuthLayout>
);

export default Page404
