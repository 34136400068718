import { configureStore } from '@reduxjs/toolkit'
import { createMiddleware as apiMiddleware } from 'redux-api-middleware'
import rootReducer from '../reducers'
import { createLogger } from 'redux-logger'
import authMiddleware from '../middlewares/authMiddleware'
import toastMiddleware from '../middlewares/toastMiddleware'
import localStorageMiddleware from '../middlewares/localStorageMiddleware'

// Configurar redux-logger solo en desarrollo
const logger = createLogger({
  level: 'info',
  collapsed: true,
})

// Configurar los middlewares
const middlewares = [
  apiMiddleware(),
  authMiddleware,
  localStorageMiddleware,
  toastMiddleware,
]

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger)
}

// Configurar la tienda (store)
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
  devTools: process.env.NODE_ENV !== 'production',
})

// Configuración para Hot Module Replacement (HMR)
if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('../reducers', () => {
    const nextRootReducer = require('../reducers').default
    store.replaceReducer(nextRootReducer)
  })
}

export default store
