import React from 'react'
import { Button, CardBody, FormGroup, Row } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'

const Contact = ({ inputStyle, setUser, user, expedient, location }) => {
  const navigate = useNavigate()

  if (
    !expedient.judicial_party ||
    expedient.hearing_type < 0 ||
    !expedient.hearing_date ||
    !expedient.dificulty ||
    !expedient.court ||
    !expedient.resume ||
    !user.name ||
    !user.last_name
  ) {
    navigate(`/create-expedient-form/name${location.search}`)
  }

  const validateCheckout = async (e, errors, values) => {
    if (errors.length > 0) {
      return toast.error('Por favor rellena los campos correctamente', {
        theme: 'colored',
      })
    }

    if (!values.phone) {
      return toast.error('Por favor introduce un telefono', {
        theme: 'colored',
      })
    }

    if (!values.email) {
      return toast.error('Por favor introduce un email', { theme: 'colored' })
    }

    setUser({ ...user, ...values })

    return navigate(`/create-expedient-form/charging${location.search}`)
  }

  return (
    <CardBody>
      <div className="d-flex justify-content-center align-items-center">
        <h2
          style={{ lineHeight: '1.5' }}
          className="col-md-6 text-center"
        >{`¡Encantados de conocerte, ${user.name}! Dinos un un teléfono y un mail para que el abogado colaborador asignado pueda contactar contigo.`}</h2>
      </div>

      <AvForm onSubmit={validateCheckout}>
        <Row className="d-flex justify-content-center align-items-center">
          <FormGroup className="col-md-3 d-md-flex justify-content-end m-md-4 mt-n4">
            <AvField
              style={inputStyle}
              name="phone"
              placeholder="Telefono"
              value={user.phone}
              validate={{
                pattern: {
                  value: '/^(\\+34|0034|34)?((8|9)[0-9]\\d{7}|(6|7)\\d{8})$/gm',
                },
              }}
              errorMessage="Introduce el teléfono, sólo números, sin espacios ni guiones"
            />
          </FormGroup>

          <FormGroup className="col-md-3 d-md-flex justify-content-start m-md-4 mt-n4">
            <AvField
              style={inputStyle}
              name="email"
              placeholder="Email"
              value={user.email}
            />
          </FormGroup>
        </Row>

        <div className="d-flex flex-md-row flex-column justify-content-md-center align-items-md-center">
          <Button color="primary" size="lg">
            Siguiente
          </Button>
        </div>
      </AvForm>
    </CardBody>
  )
}

export default Contact
