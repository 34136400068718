import * as types from '../constants'
import { toast } from 'react-toastify'

const initialState = {
  docs: [],
  user: {},
  substitutes: [],
  partners: [],
  loading: false,
}

export const users = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_USERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }

    case types.LOAD_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        partners: action.payload.partners || [],
      }

    case types.UPDATE_USER_SUCCESS:
      if (action.payload.user.imageValid === false) {
        toast.error('La imagen no es válida, por favor adjunta una nueva', {
          theme: 'colored',
        })
      } else {
        toast.success('Usuario actualizado', { theme: 'colored' })
      }
      return {
        ...state,
        user: action.payload.user,
        partners: action.payload.partners || [],
      }

    case types.UPDATE_USER_ERROR:
      if (
        action.payload.response &&
        action.payload.response.message &&
        ((action.payload.response.message.errmsg &&
          action.payload.response.message.errmsg.includes('cif_1 dup key:')) ||
          (action.payload.response.message.keyValue &&
            action.payload.response.message.keyValue.cif))
      ) {
        toast.error('Ya hay un usuario registrado con este CIF', {
          theme: 'colored',
        })
      } else {
        toast.error(
          action.payload.response?.message ||
            'Usuario no actualizado, inténtelo de nuevo',
          { theme: 'colored' }
        )
      }
      return state

    case types.LOAD_SUBTITUTES_SUCCESS:
      return {
        ...state,
        substitutes: action.payload,
      }

    case types.DELETE_USER_SUCCESS:
      toast.success('Usuario eliminado', { theme: 'colored' })
      return {
        ...state,
        user: {},
      }

    case types.DELETE_USER_ERROR:
      toast.error(
        action.payload.response.message
          ? action.payload.response.message
          : 'No se puede eliminar el usuario',
        { theme: 'colored' }
      )
      return {
        ...state,
      }
    case types.CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case types.CREATE_USER_SUCCESS:
      toast.success('Usuario creado', { theme: 'colored' })

      return {
        ...state,
        docs: [action.payload.user, ...state.docs],
        partners: [action.payload.user, ...state.partners],
        loading: false,
      }

    case types.CREATE_USER_ERROR:
      return { ...state, loading: false }

    case types.ADD_SUBSTITUTE_EXCLUDED_ERROR:
      toast.error('No se ha podido excluir al sustituto, inténtalo de nuevo', {
        theme: 'colored',
      })
      return state

    case types.ADD_SUBSTITUTE_EXCLUDED_SUCCESS:
      toast.success('Sustituto excluido', { theme: 'colored' })
      return {
        ...state,
        user: {
          ...state.user,
          substitutes_excluded: [
            ...state.user.substitutes_excluded,
            action.payload.substitute_excluded,
          ],
        },
      }

    case types.REMOVE_SUBSTITUTE_EXCLUDED_ERROR:
      toast.error('No se ha podido eliminar al sustituto, inténtalo de nuevo', {
        theme: 'colored',
      })
      return state

    case types.REMOVE_SUBSTITUTE_EXCLUDED_SUCCESS:
      toast.success('Sustituto desbloqueado', { theme: 'colored' })
      return {
        ...state,
        user: {
          ...state.user,
          substitutes_excluded: [
            ...(action.payload.user?.substitutes_excluded || []),
          ],
        },
      }

    case types.ADD_FAVORITE_SUBSTITUTE_SUCCESS:
      toast.success('Sustituto añadido a favortios', { theme: 'colored' })

      return {
        ...state,
        user: {
          ...state.user,
          substitutes_favorites: [
            ...(state.user.substitutes_favorites || []),
            action.payload.substitute_favorite,
          ],
        },
      }

    case types.REMOVE_FAVORITE_SUBSTITUTE_SUCCESS:
      toast.success('Sustituto desmarcado como favorito', { theme: 'colored' })
      return {
        ...state,
        user: {
          ...state.user,
          substitutes_favorites: [
            ...(action.payload.user?.substitutes_favorites || []),
          ],
        },
      }

    case types.SET_LINKED_PARTNERS_SUCCESS:
    case types.DELETE_LINKED_PARTNER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          linked_partners: [...action.payload.partner.linked_partners],
        },
      }

    case types.SET_LINKED_PARTNERS_ERROR:
      toast.error('No se han podido asociar los partners seleccionados', {
        theme: 'colored',
      })
      return state

    case types.DELETE_LINKED_PARTNER_ERROR:
      toast.error('No se han podido desvincular el partner', {
        theme: 'colored',
      })
      return state

    case types.ADD_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          custom_fields: action.payload.custom_fields,
        },
      }

    case types.UPDATE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          custom_fields: action.payload.custom_fields,
        },
      }

    case types.ADD_CUSTOM_FIELD_ERROR:
      if (action.payload.status === 400)
        toast.error('Solo se permiten 5 campos personalizados activos', {
          theme: 'colored',
        })
      return {
        ...state,
      }

    case types.UPDATE_CUSTOM_FIELD_ERROR:
      if (action.payload.status === 400)
        toast.error('Solo se permiten 5 campos personalizados activos', {
          theme: 'colored',
        })
      return {
        ...state,
      }

    case types.CHANGE_PARTNER_TO_COMPANY_SUCCESS:
      toast.success('Cambio de empresa realizado correctamente', {
        theme: 'colored',
      })
      return {
        ...state,
        user: action.payload.user,
      }
    case types.CHANGE_PARTNER_TO_COMPANY_ERROR:
      toast.error('Ha ocurrido algun error y no se ha realizado el cambio', {
        theme: 'colored',
      })
      return {
        ...state,
      }

    case types.ADD_AUTH_POOL_SUCCESS:
    case types.EDIT_AUTH_POOL_SUCCESS:
      toast.success('Se ha realizado el cambio correctamente', {
        theme: 'colored',
      })

      return {
        ...state,
        user: action.payload.user,
      }
    case types.ADD_AUTH_POOL_ERROR:
    case types.EDIT_AUTH_POOL_ERROR:
      if (action.payload.status === 409)
        toast.error('Ya existe un pool con el mismo id/nombre', {
          theme: 'colored',
        })
      else
        toast.error('Ha ocurrido algun error y no se ha realizado el cambio', {
          theme: 'colored',
        })
      return {
        ...state,
      }

    default:
      return state
  }
}
