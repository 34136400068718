import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  specialPrices: [],
  created: {},
}

export const specialPrices = (state = defaultState, action) => {
  switch (action.type) {
    case types.CREATE_SPECIAL_PRICE_SUCCESS:
      return {
        ...state,
        created: action.payload,
        specialPrices: [...state.specialPrices, action.payload.userPrice],
      }

    case types.CREATE_SPECIAL_PRICE_ERROR:
      toast.error(
        action.payload.response.message === 11000
          ? 'Producto y partido judicial duplicado'
          : 'Error al crear el precio especial',
        { theme: 'colored' }
      )
      return {
        ...state,
      }

    case types.GET_SPECIAL_PRICES_SUCCESS:
      return {
        ...state,
        specialPrices: action.payload,
      }

    case types.GET_SPECIAL_PRICES_ERROR:
      toast.error('Error al obtener los precios especiales para el usuario', {
        theme: 'colored',
      })

      return {
        ...state,
      }

    case types.UPDATE_SPECIAL_PRICE_SUCCESS:
      return {
        ...state,
        specialPrices: state.specialPrices.map((e) =>
          e._id === action.payload.userPrice._id ? action.payload.userPrice : e
        ),
      }

    case types.UPDATE_SPECIAL_PRICE_ERROR:
      toast.error('Error al actualizar el precio especial', {
        theme: 'colored',
      })
      return {
        ...state,
      }

    case types.DELETE_SPECIAL_PRICE_SUCCESS:
      return {
        ...state,
        specialPrices: state.specialPrices.filter(
          (e) => e._id !== action.payload.userPrice
        ),
      }

    case types.DELETE_SPECIAL_PRICE_ERROR:
      toast.error('Error al eliminar el precio especial', { theme: 'colored' })
      return {
        ...state,
      }

    default:
      return state
  }
}
