import React from "react"
import { Container } from "reactstrap"
import LightHeader from '../components/common/LightHeader'

const Page = ({ children, lightHeader }) => (
  <React.Fragment>
    {
      lightHeader && <LightHeader />
    }
    <Container className="d-flex flex-column">
      {children}
    </Container>
  </React.Fragment>
);

export default Page
