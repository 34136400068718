import { googleLogout } from '../../services/socialLogin'
import * as types from '../constants'

const authMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case types.CREATE_CLIENT_ERROR:
      if (action.meta?.isIDP) {
        setTimeout(() => googleLogout(), 2000)
      }
      break

    default:
      break
  }

  return next(action)
}

export default authMiddleware
