import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  urgentIssues: [],
  urgentUsers: [],
}

export const urgentIssues = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_URGENT_ISSUES_LIST_SUCCESS:
      return {
        ...state,
        urgentIssues: action.payload.documents,
        urgentUsers: action.payload.urgentUsers,
      }

    case types.GET_URGENT_ISSUES_LIST_ERROR:
      toast.error('Ha ocurrido un error, cargando los temas urgentes', {
        theme: 'colored',
      })
      return {
        ...state,
        urgentIssues: [],
        urgentUsers: [],
      }

    default:
      return state
  }
}
