import React, { useState, useMemo, useEffect } from 'react'
import { promotionValidator } from '../redux/actions/promotion'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { expedientStatusBadge, roundToTwoDecimals } from '../services/index'
import { useDispatch, useSelector } from 'react-redux'

const PaymentSummary = ({ basePrice = 95, expedient, goBack, loading }) => {
  const dispatch = useDispatch()
  const { userInvoiceAccounts } = useSelector(
    (state) => state.userInvoiceAccount
  )
  const { promotion } = useSelector((state) => state.promotion)

  const isValidPromotion = promotion.discount && !promotion?.err
  const [showPromoDiscount, setShowPromoDiscount] = useState(false)
  const [promotionName, setPromotionName] = useState('')
  const [showStatusPromotion, setStatusPromotion] = useState()

  const currentAccount = useMemo(() => {
    return userInvoiceAccounts.find(
      (account) => account._id === expedient.user_invoice_account_id
    )
  }, [expedient, userInvoiceAccounts])

  useEffect(() => {
    if (isValidPromotion && promotion.discount) {
      let { discount = {} } = promotion

      let { percentatge = null, value = null } = discount

      let discountValue = percentatge !== null ? percentatge : value

      if (
        discountValue === percentatge &&
        basePrice - (basePrice * percentatge) / 100 < basePrice * 0.1
      ) {
        percentatge = 90
      }

      if (discountValue === value && basePrice - value < 5) {
        value = basePrice - 5
      }
    }
  }, [promotion, basePrice, isValidPromotion])

  const handleChangePromotion = (event) => {
    setStatusPromotion(false)
    setPromotionName(event.target.value)
  }

  const validatePromotion = () => {
    dispatch(promotionValidator({ name: promotionName }))
    setStatusPromotion(true)
  }

  const calculateFinalPrice = (basePrice, promotion) => {
    if (isValidPromotion) {
      if (promotion.discount?.value) {
        return roundToTwoDecimals(basePrice - promotion.discount.value)
      }
      if (promotion.discount?.percentatge) {
        return roundToTwoDecimals(
          basePrice - basePrice * (promotion.discount.percentatge / 100)
        )
      }
    }
    return basePrice
  }

  const displayTaxLabel = (account) => {
    return !account || account?.tax_zone === 'spain' ? 'IVA (21%):' : 'Sin IVA:'
  }

  const applyTax = (price, taxZone = 'spain') => {
    if (taxZone === 'spain') {
      return roundToTwoDecimals(price * 1.21)
    }
    return roundToTwoDecimals(price)
  }

  const displayAppliedTax = (basePrice, promotion, account) => {
    const finalPrice = calculateFinalPrice(basePrice, promotion)

    return roundToTwoDecimals(
      Math.abs(finalPrice - applyTax(finalPrice, account?.tax_zone))
    )
  }

  const displayTotal = (basePrice, promotion, account) => {
    let priceAfterPromotion = basePrice

    if (isValidPromotion) {
      if (promotion.discount?.value) {
        priceAfterPromotion -= promotion.discount.value
      } else if (promotion.discount?.percentatge) {
        priceAfterPromotion -=
          basePrice * (promotion.discount.percentatge / 100)
      }
    }

    return priceAfterPromotion > 0
      ? roundToTwoDecimals(applyTax(priceAfterPromotion, account?.tax_zone))
      : 0
  }

  const getDiscountedPrice = (basePrice, promotion) => {
    if (promotion.discount?.value) {
      return roundToTwoDecimals(basePrice - promotion.discount.value)
    } else if (promotion.discount?.percentatge) {
      return roundToTwoDecimals(
        basePrice - basePrice * (promotion.discount.percentatge / 100)
      )
    }

    return basePrice
  }

  const displayDiscountedPrice = (basePrice, promotion) => {
    const discountedPrice = isValidPromotion
      ? getDiscountedPrice(basePrice, promotion)
      : 0
    return roundToTwoDecimals(discountedPrice)
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <div
          id="createHearingAdminCompanyPartner"
          disabled={loading}
          onClick={goBack}
          style={{ cursor: 'pointer' }}
        >
          <FontAwesomeIcon className="ms-3" size="2x" icon={faArrowLeft} />
        </div>
        <h4 className="blue2">Resumen del pago</h4>
        <div></div>
      </div>
      <div className="card">
        <div className="card-body">
          <dl className="d-flex">
            {expedient.is_urgent_hearing ? (
              <dt>Precio:</dt>
            ) : (
              <dt>
                Precio{' '}
                <span
                  onClick={() => setShowPromoDiscount(true)}
                  className="small text-secondary pointer"
                >
                  (tengo código de descuento)
                </span>
                :
              </dt>
            )}
            <dd className="ms-auto">
              {basePrice - (expedient.extra_urgent_price || 0)} €
            </dd>
          </dl>

          {expedient.extra_urgent_price > 0 && (
            <dl className="d-flex">
              <dt>Extra Encargo Urgente:</dt>
              <dd className="ms-auto">{expedient.extra_urgent_price} €</dd>
            </dl>
          )}

          {showPromoDiscount && (
            <div className="d-flex flex-row mb-3 align-items-center">
              <div className="col-5">
                <div className="form-group d-flex align-items-center w-100">
                  <input
                    onChange={handleChangePromotion}
                    value={promotionName || ''}
                    name="promotion"
                    id="promotion"
                    type="text"
                    placeholder="Introduzca código promocional"
                    className="is-untouched is-pristine av-invalid form-control me-3"
                  />
                </div>
              </div>
              {promotionName !== '' && !showStatusPromotion && (
                <div className="ms-auto flex-column">
                  <Button
                    size="md"
                    className="ms-auto text-center btn btn-success"
                    onClick={validatePromotion}
                  >
                    Validar
                  </Button>
                </div>
              )}
              {(promotion?.discount?.value ||
                promotion?.discount?.percentatge ||
                promotion.err) &&
                showStatusPromotion && (
                  <span className="ms-auto">
                    {expedientStatusBadge(
                      isValidPromotion ? 'valido' : 'invalido'
                    )}
                  </span>
                )}
            </div>
          )}

          {isValidPromotion && (
            <div>
              <dl className="d-flex">
                <dt>Descuento: </dt>
                <dd className="ms-auto">
                  {promotion.discount?.value
                    ? `${promotion.discount?.value}€`
                    : `${promotion.discount?.percentatge}%`}
                </dd>
              </dl>

              <dl className="d-flex">
                <dt>Precio aplicado: </dt>
                <dd className="ms-auto">
                  {displayDiscountedPrice(basePrice, promotion)}€
                </dd>
              </dl>
            </div>
          )}

          <dl className="d-flex">
            <dt>{displayTaxLabel(currentAccount)} </dt>
            <dd className="ms-auto">
              <strong>
                {displayAppliedTax(basePrice, promotion, currentAccount)}€
              </strong>
            </dd>
          </dl>

          <hr />

          <dl className="d-flex">
            <dt>Total: </dt>
            <dd className="ms-auto text-dark b">
              <strong>
                {displayTotal(basePrice, promotion, currentAccount)}€{' '}
              </strong>
            </dd>
          </dl>
        </div>
      </div>
    </>
  )
}

export default PaymentSummary
