import React, { memo } from 'react'
import { DateTimePicker } from 'react-widgets'
import { toast } from 'react-toastify'

function isValidDate(date) {
  return date instanceof Date && !isNaN(date)
}

function safeDateConversion(input) {
  if (['string', 'number', 'object'].includes(typeof input) && input) {
    const date = new Date(input)
    return isValidDate(date) ? date : undefined
  }
  return undefined
}

const DateTimePickerCustomize = memo(
  ({ min, onChange, value, style, readOnly = false, errorMessage }) => {
    value = safeDateConversion(value)

    const update = (value) => {
      if (value.getDay() === 0 || value.getDay() === 6) {
        return toast.error(
          errorMessage || 'No se puede hacer un encargo en fin de semana',
          { theme: 'colored' }
        )
      }

      return onChange(value)
    }

    return (
      <DateTimePicker
        style={style}
        min={min}
        required
        placeholder={'Click en los iconos -> (fecha y hora)'}
        format={'[Dia:] DD/MM/YYYY  [Hora:] hh:mm A'}
        step={1}
        culture="es"
        onChange={update}
        value={value}
        timeComponent={({ value, text }) =>
          value.getHours() >= 8 &&
          value.getHours() < 18 &&
          value.getMinutes() % 5 === 0
            ? text
            : ''
        }
        onKeyDown={(e) => e.preventDefault()}
        readOnly={readOnly}
      />
    )
  }
)

export default DateTimePickerCustomize
