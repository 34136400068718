const demandaEjecucionSentencia = {
  "id": "demanda_ejecucion_sentencia",
  "text": "Demanda de ejecución de Sentencia",
  "price": 45,
  "cost": 25,
  "data": [
    {
      "id": "demandantes",
      "text": "Demantantes",
      "type": "array",
      "required": true,
      "attributes": [
        {
          "id": "nombre",
          "text": "Nombre",
          "type": "text",
          "required": true
        },
        {
          "id": "nif_dni",
          "text": "NIF/DNI",
          "type": "text",
          "required": true
        },
        {
          "id": "domicilio",
          "text": "Domicilio",
          "type": "text",
          "required": true
        }
      ]
    },
    {
      "id": "demandados",
      "text": "Demandados",
      "type": "array",
      "required": true,
      "attributes": [
        {
          "id": "nombre",
          "text": "Nombre",
          "type": "text",
          "required": true
        },
        {
          "id": "nif_dni",
          "text": "NIF/DNI",
          "type": "text",
          "required": true
        },
        {
          "id": "domicilio",
          "text": "Domicilio",
          "type": "text",
          "required": true
        }
      ]
    },
    {
      "id": "abogado",
      "text": "Abogado que firma la demanda",
      "type": "text",
      "required": true
    },
    {
      "id": "colegiado_abogado",
      "text": "Número de colegiado abogado",
      "type": "text",
      "required": true
    },
    {
      "id": "procurador",
      "text": "Procurador que firma la demanda",
      "type": "text",
      "required": true
    },
    {
      "id": "juzgado",
      "text": "Juzgado donde se presenta la demanda",
      "type": "text",
      "required": true
    },
    {
      "id": "numero_juzgado",
      "text": "Número de Juzgado",
      "type": "text",
      "required": true
    },
    {
      "id": "autos",
      "text": "Autos",
      "type": "text",
      "required": true
    },
    {
      "id": "importe_condena",
      "text": "Importe de la condena en Sentencia",
      "type": "number",
      "required": true
    },
    {
      "id": "fecha_sentencia",
      "text": "Fecha de la sentencia",
      "type": "date",
      "required": true
    },
    {
      "id": "sentencia",
      "text": "Sentencia a ejecutar",
      "type": "upload",
      "required": true
    },
    {
      "id": "otros_documentos",
      "text": "Otros documentos que consideres oportunos",
      "type": "upload",
      "required": false
    },
    {
      "id": "notas",
      "text": "Notas para nuestro abogado",
      "type": "textarea",
      "required": true
    }
  ]
}

export default demandaEjecucionSentencia