import * as types from '../constants'

const initialState = {
  admin: {},
  client: {
    tableData: [
      { label: 'Expedientes', value: 25 },
      { label: 'Notarías', value: 25 },
      { label: 'Procuras', value: 25 },
      { label: 'OnCall', value: 25 },
    ],
    workedWith: 0,
    judicialParties: 0,
    totalOrders: 0,
  },
  loading: false,
  isRefreshing: false,
}

export const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_ADMIN_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case types.LOAD_ADMIN_DASHBOARD_SUCCESS:
      return {
        ...state,
        admin: {
          ...action.payload,
        },
        loading: false,
      }
    case types.LOAD_CLIENT_DASHBOARD_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          tableData: action.payload.tableData || state.client.tableData,
          workedWith: action.payload.workedWith || state.client.workedWith,
          judicialParties:
            action.payload.judicialParties || state.client.judicialParties,
          totalOrders: action.payload.totalOrders || state.client.totalOrders,
          calendar: action.payload.calendar,
        },
      }
    case types.REFRESH_CLIENT_DASHBOARD_REQUEST:
      return {
        ...state,
        isRefreshing: true,
      }
    case types.REFRESH_CLIENT_DASHBOARD_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          ...action.payload.client,
        },
        isRefreshing: false,
      }
    default:
      return state
  }
}
