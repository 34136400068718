import React, { Fragment } from 'react';
import { Eye, EyeOff } from 'react-feather';

export function PasswordEye({ showPassword, handleOnHover }) {

  return (
    <Fragment>
      {showPassword ? (
        <div
          className='d-flex justify-content-around align-items-center'
          style={{
            border: '1px solid lightgrey',
            borderLeft: 'none',
            width: 'calc(2.2rem + 2px)',
            height: 'calc(2.2rem + 2px)',
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',
            background: 'none',
          }}
        >
          <EyeOff
            size={18}
            className='pointer'
            color='lightgrey'
            onClick={() => handleOnHover()} />
        </div>
      ) : (
        <div
          className='d-flex justify-content-around align-items-center'
          style={{
            border: '1px solid lightgrey',
            borderLeft: 'none',
            width: 'calc(2.2rem + 2px)',
            height: 'calc(2.2rem + 2px)',
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',
            background: 'none',
          }}
        >
          <Eye
            size={18}
            className='pointer'
            color='lightgrey'
            onClick={() => handleOnHover()} />
        </div>
      )}
    </Fragment>
  );
}
