import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation'
import { Col, Label, Row } from 'reactstrap'
import { toast } from 'react-toastify'
import MultiString from './MultiString'
import UploadDocsForm from './UploadDocsForm'
import Loading from '../common/Loading'
import {
  createJudicialBrief,
  updateJudicialBrief,
} from '../../redux/actions/judicialBrief'
import UserSearch from '../search/UserSearch'
import { gtmEvent } from '../../services/gtm-handler'
import judicialBriefForms from '../../services/judicialBriefForms'
import {
  CREATE_JUDICIAL_BRIEF_SUCCESS,
  UPDATE_JUDICIAL_BRIEF_SUCCESS,
} from '../../redux/constants'
import { useNavigate } from 'react-router'

const FormBuilder = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const {
    selectedTemplate,
    defaultText,
    closeModal,
    formDisabled = false,
    showHeader = true,
    brief = {},
    action,
  } = props

  const { data = {} } = brief
  const { activeUser } = auth
  const navigate = useNavigate()

  const [form, setForm] = useState({})
  const [disabledForm, setDisabledForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [creatorId, setCreatorId] = useState(null)

  useEffect(() => {
    const selectedForm =
      judicialBriefForms.find((f) => f.id === selectedTemplate) || {}
    setForm(selectedForm)
  }, [selectedTemplate])

  useEffect(() => {
    setCreatorId(brief.creator_id || null)
  }, [brief.creator_id])

  const submitForm = async (e, errors, formData) => {
    if (errors.length > 0) {
      return toast.error(
        <div>
          Por favor rellena los campos correctamente:
          {errors.map((err) => {
            const field = form.data.find((d) => err === d.id)
            return field && field.type === 'upload' ? (
              <div key={`err-${field.id}`}>- {field.text}</div>
            ) : null
          })}
        </div>,
        { theme: 'colored' }
      )
    }

    setDisabledForm(true)
    setLoading(true)

    const newJudicialBrief = {
      template: selectedTemplate,
      creator_id: activeUser.role !== 'admin' ? activeUser._id : creatorId,
      data: formData,
    }

    if (action !== 'update') {
      dispatch(createJudicialBrief({ judicialBrief: newJudicialBrief }))
        .then((res) => {
          if (res.type === CREATE_JUDICIAL_BRIEF_SUCCESS) {
            const { judicialBrief } = res.payload
            gtmEvent({
              event: 'purchase',
              ecommerce: {
                currencyCode: 'EUR',
                purchase: {
                  actionField: {
                    id: judicialBrief._id,
                    revenue: judicialBrief.price,
                    tax: judicialBrief.price * 0.21,
                    creatorId: judicialBrief.creator_id,
                  },
                  products: [
                    {
                      id: 'JB',
                      name: 'Compra escrito judicial',
                      price: judicialBrief.price,
                      quantity: 1,
                    },
                  ],
                },
              },
            })

            closeModal()
            setDisabledForm(false)
            setLoading(false)
            navigate('/panel/judicial-briefs')
          }
        })
        .catch(() => {
          setDisabledForm(false)
          setLoading(false)
        })
    } else {
      dispatch(
        updateJudicialBrief({ judicialBrief: newJudicialBrief, id: brief._id })
      )
        .then((res) => {
          if (res.type === UPDATE_JUDICIAL_BRIEF_SUCCESS) {
            setDisabledForm(false)
            setLoading(false)
          }
        })
        .catch(() => {
          setDisabledForm(false)
          setLoading(false)
        })
    }
  }

  if (selectedTemplate === defaultText) {
    return <div />
  }

  return (
    <div className={showHeader ? 'mt-2' : ''}>
      <Loading loading={loading} />
      {showHeader && (
        <div>
          <h2>{form.text}</h2>
          <div>Precio: {form.price} €</div>
        </div>
      )}
      <AvForm
        key={'judicialBriefForm'}
        onSubmit={submitForm}
        className={showHeader ? 'mt-4' : ''}
      >
        {activeUser &&
          activeUser.role === 'admin' &&
          !Object.keys(brief).length &&
          !brief.creator_id && (
            <Row className="mb-3">
              <Col xs="12" sm="6" md="4">
                <Label>Creador del encargo</Label>
                <div className="mb-3">
                  <UserSearch
                    onSubmit={(user) => setCreatorId(user ? user.value : null)}
                  />
                </div>
              </Col>
              {creatorId && (
                <Col xs="12" sm="6" md="4" className="mt-2">
                  User ID: {creatorId}
                </Col>
              )}
            </Row>
          )}

        <Row>
          <Col xs="12" sm="6" md="4">
            <div>
              <AvField
                name="internal_reference"
                required={true}
                label="Referencia interna"
                disabled={formDisabled}
                value={data.internal_reference}
              />
            </div>
          </Col>

          {form.data &&
            form.data.map((d) => (
              <InputBuilder
                field={d}
                key={d.id}
                formDisabled={formDisabled}
                data={data}
                submitForm={submitForm}
                judicialBriefId={brief._id}
              />
            ))}
        </Row>

        {!activeUser.is_judicial_brief && (
          <div className="d-flex mt-3">
            <button
              id="judicialBriefForm"
              className="btn btn-success btn-lg"
              disabled={disabledForm}
            >
              {data && data.internal_reference
                ? 'Guardar encargo'
                : 'Crear encargo'}
            </button>
          </div>
        )}
      </AvForm>
    </div>
  )
}

export default FormBuilder

const InputBuilder = (props) => {
  const { field, formDisabled, data, submitForm, judicialBriefId } = props
  const { type, text, required = false, id, options } = field

  if (type === 'text') {
    return (
      <Col xs="12" sm="6" md="4">
        <AvField
          name={id}
          required={required}
          label={text}
          disabled={formDisabled}
          value={data[id]}
        />
      </Col>
    )
  }

  if (type === 'number') {
    return (
      <Col xs="12" sm="6" md="4">
        <AvField
          name={id}
          required={required}
          label={text}
          type="number"
          disabled={formDisabled}
          value={data[id]}
        />
      </Col>
    )
  }

  if (type === 'date') {
    return (
      <Col xs="12" sm="6" md="4">
        <AvField
          name={id}
          required={required}
          label={text}
          type="date"
          disabled={formDisabled}
          value={data[id]}
        />
      </Col>
    )
  }

  if (type === 'textarea') {
    return (
      <Col md="12" className="form-group">
        <label>{text}</label>
        <AvInput
          type="textarea"
          name={id}
          required={required || false}
          rows="5"
          disabled={formDisabled}
          value={data[id]}
        />
      </Col>
    )
  }

  if (type === 'checkbox') {
    return (
      <Col xs="12" sm="6" md="4">
        <AvInput
          type="checkbox"
          name={id}
          disabled={formDisabled}
          className="ms-1"
          checked={!!data[id]}
        />
        <label className="ms-4">{text}</label>
      </Col>
    )
  }

  if (type === 'select') {
    return (
      <Col xs="12" sm="6" md="4">
        <label>{text}</label>
        <AvInput
          type="select"
          name={id}
          disabled={formDisabled}
          value={data[id]}
        >
          {!!options.length &&
            options.map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
        </AvInput>
      </Col>
    )
  }

  if (type === 'upload') {
    return (
      <UploadDocsForm
        field={field}
        formDisabled={formDisabled}
        data={data}
        submitForm={submitForm}
        judicialBriefId={judicialBriefId}
      />
    )
  }

  if (type === 'array') {
    return <MultiString field={field} formDisabled={formDisabled} data={data} />
  }

  return null
}
