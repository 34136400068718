import { useEffect } from 'react'
import { Button, CardBody, FormGroup, Row } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router'

const CollegiateNumber = ({ inputStyle, setUser, user }) => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (
      !user.name ||
      !user.last_name ||
      !user.phone ||
      !user.email ||
      !user.password ||
      !user.role ||
      !user.substitute_judicial_parties
    ) {
      navigate(`/create-user-form/name${location.search}`)
    }
  }, [user, navigate, location.search])

  const handleSubmit = (e, errors, values) => {
    if (errors.length > 0) {
      toast.error('Por favor rellena los campos correctamente', {
        theme: 'colored',
      })
      return
    }

    if (!values.colegiado) {
      toast.error('Por favor introduce una referencia interna', {
        theme: 'colored',
      })
      return
    }

    setUser({ ...user, colegiado: values.colegiado })
    navigate(`/create-user-form/skills${location.search}`)
  }

  return (
    <CardBody>
      <div className="d-flex justify-content-center align-items-center">
        <h2 style={{ lineHeight: '1.5' }} className="col-md-6 text-center">
          {`${user.name}, ¿cúal es tu número de colegiado?`}
        </h2>
      </div>

      <AvForm onSubmit={handleSubmit}>
        <Row className="d-flex justify-content-center align-items-center">
          <FormGroup className="col-md-3 d-md-flex justify-content-center m-md-4 mt-n4">
            <AvField
              style={inputStyle}
              name="colegiado"
              id="colegiado"
              placeholder="Número de colegiado"
              value={user.colegiado}
              required
            />
          </FormGroup>
        </Row>
        <div className="d-flex flex-md-row flex-column justify-content-md-center align-items-md-center">
          <Button color="primary" size="lg" type="submit">
            Siguiente
          </Button>
        </div>
      </AvForm>
    </CardBody>
  )
}

export default CollegiateNumber
