import React, { Fragment } from 'react'
import Logo from "../components/common/Logo"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Main from "../components/common/Main";

const Maintenance = () => {
  return (
    <Fragment>
      <Main className="d-flex w-100">
        <Container className="d-flex flex-column">
          <Row className="h-100">
            <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center">
                  <Logo className='mb-5' height='90' />
                </div>
                <Card>
                  <CardBody>
                    <h2 className='text-center'>EN MANTENIMIENTO</h2>
                    <div className="m-sm-4 p-2 text-center my-2" style={{border: '1px solid #42A4FF', fontSize: '1.1em'}}>
                      <p className="mb-2">Estamos realizando tareas de mantenimiento en la aplicación. Por ello hemos deshabilitado el acceso de forma temporal.</p>
                      <p className="m-0 mt-3 ">Intenta acceder más tarde.</p>
                      <p className="mb-2"> Muchas gracias por tu colaboración y disculpa las molestias. </p>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  )
}

export default Maintenance

