import { RSAA } from 'redux-api-middleware'
import { baseAPI } from '../../config'
import * as types from '../constants'

export function getJudicialBriefs({ query }) {
  const token = localStorage.getItem('token')

  const urlSearchParams = new URLSearchParams()

  for (const key in query) {
    if (query.hasOwnProperty(key)) {
      urlSearchParams.append(key, query[key])
    }
  }

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/judicial-briefs?${urlSearchParams.toString()}`,
      method: 'GET',
      types: [
        types.GET_JUDICIAL_BRIEFS_REQUEST,
        types.GET_JUDICIAL_BRIEFS_SUCCESS,
        types.GET_JUDICIAL_BRIEFS_ERROR,
      ],
    },
  }
}

export function getJudicialBrief({ id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/judicial-briefs/${id}`,
      method: 'GET',
      types: [
        types.GET_JUDICIAL_BRIEF_REQUEST,
        types.GET_JUDICIAL_BRIEF_SUCCESS,
        types.GET_JUDICIAL_BRIEF_ERROR,
      ],
    },
  }
}

export function createJudicialBrief({ judicialBrief }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(judicialBrief),
      endpoint: `${baseAPI}/judicial-briefs`,
      method: 'POST',
      types: [
        types.CREATE_JUDICIAL_BRIEF_REQUEST,
        types.CREATE_JUDICIAL_BRIEF_SUCCESS,
        types.CREATE_JUDICIAL_BRIEF_ERROR,
      ],
    },
  }
}

export function updateJudicialBrief({ judicialBrief, id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(judicialBrief),
      endpoint: `${baseAPI}/judicial-briefs/${id}`,
      method: 'PUT',
      types: [
        types.UPDATE_JUDICIAL_BRIEF_REQUEST,
        types.UPDATE_JUDICIAL_BRIEF_SUCCESS,
        types.UPDATE_JUDICIAL_BRIEF_ERROR,
      ],
    },
  }
}

export function deleteJudicialBrief({ id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/judicial-briefs/${id}`,
      method: 'DELETE',
      types: [
        types.DELETE_JUDICIAL_BRIEF_REQUEST,
        types.DELETE_JUDICIAL_BRIEF_SUCCESS,
        types.DELETE_JUDICIAL_BRIEF_ERROR,
      ],
    },
  }
}
