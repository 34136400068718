import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  expedients: {},
  created: {},
  expedient: {},
  assigned: {},
  drafts: {},
}

export const possession = (state = defaultState, action) => {
  switch (action.type) {
    case types.CREATE_POSSESSION_SUCCESS:
      toast.success('Encargo creado', { theme: 'colored' })

      return {
        ...state,
        created: action.payload.expedient,
      }

    case types.CREATE_POSSESSION_ERROR:
      const message = action.payload?.response?.message

      toast.error(
        message ||
          'Ha ocurrido un error, si persiste contacte con el administrador',
        { theme: 'colored' }
      )
      return {
        ...state,
        error: action.payload
          ? action.payload.response.message
          : 'Error desconocido',
      }

    default:
      return state
  }
}
