import { useEffect } from 'react'
import { Button, CardBody, Container, Card } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FaRegHandshake } from 'react-icons/fa' // Icono de apretón de manos

const ThanksSubstitute = () => {
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/create-user-form/name')
    }
  }, [navigate])

  const handleButtonClick = () => {
    navigate(
      auth.activeUser.is_procurador
        ? '/panel/assigned-procuras'
        : '/panel/assigned-expedients'
    )
  }

  return (
    <Container className="vh-100 d-flex align-items-center justify-content-center">
      <Card className="shadow-lg p-4" style={{ maxWidth: '700px', borderRadius: '15px' }}>
        <CardBody className="text-center">
          <div className="mb-4">
            <FaRegHandshake size={50} color="#0098FA" />
          </div>
          <h2 style={{ lineHeight: '1.5' }} className="mb-4">
            ¡Gracias por registrarte! 🎉
          </h2>
          <p className="lead mb-4">
            Nuestro equipo revisará y validará tu solicitud lo antes posible.
            Con tu cuenta, también puedes solicitar sustituciones cuando lo
            necesites. ¡Estamos encantados de contar contigo en nuestra red!
          </p>
          <Button onClick={handleButtonClick} color="primary" size="lg" className="px-4">
            Ir a la aplicación
          </Button>
        </CardBody>
      </Card>
    </Container>
  )
}

export default ThanksSubstitute
