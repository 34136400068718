import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  created: {},
  issue: {},
}

export const issue = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_ISSUE_SUCCESS:
      return {
        ...state,
        issue: action.payload.issue,
      }

    case types.GET_ISSUE_ERROR:
      toast.error('Ha ocurrido algún error', { theme: 'colored' })
      return state

    case types.CREATE_ISSUE_SUCCESS:
      toast.success('Incidencia creada', { theme: 'colored' })

      return {
        ...state,
        created: action.payload.issue,
      }

    case types.CREATE_ISSUE_ERROR:
      toast.error('Ha ocurrido algún error', { theme: 'colored' })
      return state

    case types.UPDATE_ISSUE_SUCCESS:
      toast.success('Incidencia actualizada', { theme: 'colored' })
      return {
        ...state,
        issue: {
          ...state.issue,
          ...action.payload.issue,
        },
      }

    case types.UPDATE_ISSUE_ERROR:
      toast.error('Ha ocurrido algún error', { theme: 'colored' })
      return state

    default:
      return state
  }
}
