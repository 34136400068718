import { RSAA } from 'redux-api-middleware'
import { baseAPI } from '../../config'
import * as types from '../constants'

export function getExpedientReview({ expedient_id, document_type }) {
  if (!document_type) {
    document_type = 'expedient'
  }
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/substituteReview/expedient/${expedient_id}/${document_type}`,
      method: 'GET',
      types: [
        types.GET_EXPEDIENT_REVIEW_REQUEST,
        types.GET_EXPEDIENT_REVIEW_SUCCESS,
        types.GET_EXPEDIENT_REVIEW_ERROR,
      ],
    },
  }
}

export function createSubstituteReview({ review_data }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/substituteReview/`,
      method: 'POST',
      body: JSON.stringify(review_data),
      types: [
        types.CREATE_SUBSTITUTE_REVIEW_REQUEST,
        types.CREATE_SUBSTITUTE_REVIEW_SUCCESS,
        types.CREATE_SUBSTITUTE_REVIEW_ERROR,
      ],
    },
  }
}

export function updateSubstituteReview({ review_id, score, feedback = '' }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      endpoint: `${baseAPI}/substituteReview/${review_id}`,
      body: JSON.stringify({ score, feedback }),
      method: 'PUT',
      types: [
        types.UPDATE_SUBSTITUTE_REVIEW_REQUEST,
        types.UPDATE_SUBSTITUTE_REVIEW_SUCCESS,
        types.UPDATE_SUBSTITUTE_REVIEW_ERROR,
      ],
    },
  }
}

export function getSubstitutesReview({ substitute_id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      endpoint: `${baseAPI}/substituteReview/substitute/${substitute_id}`,
      method: 'GET',
      types: [
        types.GET_SUBSTITUTE_REVIEWS_REQUEST,
        types.GET_SUBSTITUTE_REVIEWS_SUCCESS,
        types.GET_SUBSTITUTE_REVIEWS_ERROR,
      ],
    },
  }
}
