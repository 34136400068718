const demandaReclamacionDeudaEntreParticularesOrdinario = {
  "id": "demanda_reclamacion_deuda_particulares_ordinario",
  "text": "Demanda de reclamación de deuda entre particulares (juicio ordinario)",
  "price": 65,
  "cost": 40,
  "data": [
    {
      "id": "demandantes",
      "text": "Demantantes",
      "item": 'Demandante',
      "type": "array",
      "required": true,
      "attributes": [
        {
          "id": "nombre",
          "text": "Nombre",
          "type": "text",
          "required": true
        },
        {
          "id": "nif_dni",
          "text": "NIF/DNI",
          "type": "text",
          "required": true
        },
        {
          "id": "domicilio",
          "text": "Domicilio",
          "type": "text",
          "required": true
        }
      ]
    },
    {
      "id": "demandados",
      "text": "Demandados",
      "type": "array",
      "required": true,
      "attributes": [
        {
          "id": "nombre",
          "text": "Nombre",
          "type": "text",
          "required": true
        },
        {
          "id": "nif_dni",
          "text": "NIF/DNI",
          "type": "text",
          "required": true
        },
        {
          "id": "domicilio",
          "text": "Domicilio",
          "type": "text",
          "required": true
        }
      ]
    },
    {
      "id": "abogado",
      "text": "Abogado que firma la demanda",
      "type": "text",
      "required": true
    },
    {
      "id": "colegiado_abogado",
      "text": "Número de colegiado abogado",
      "type": "text",
      "required": true
    },
    {
      "id": "procurador",
      "text": "Procurador que firma la demanda",
      "type": "text",
      "required": true
    },
    {
      "id": "poder_para_pleito",
      "text": "Tipo de poder de representación del procurador",
      "type": "select",
      "required": true,
      "options" :['poder para pleitos','apud acta que se realizará en el juzgado una vez requeridos al efecto']
    },
    {
      "id": "juzgado",
      "text": "Juzgado donde se presenta la demanda",
      "type": "text",
      "required": true
    },
    {
      "id": "importe_deuda",
      "text": "Importe de la deuda que se reclama",
      "type": "number",
      "required": true
    },
    {
      "id": "documentacion_deuda",
      "text": "Documentación que acredita la deuda",
      "type": "upload",
      "required": true
    },
    {
      "id": "burofax_previo",
      "text": "Reclamación previa (optativo)",
      "type": "upload",
      "required": false
    },
    {
      "id": "otros_documentos",
      "text": "Otros documentos que consideres oportunos",
      "type": "upload",
      "required": false
    },
    {
      "id": "notas",
      "text": "Notas para nuestro abogado",
      "type": "textarea",
      "required": true
    }
  ]
}

export default demandaReclamacionDeudaEntreParticularesOrdinario