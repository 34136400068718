import React, { useState } from 'react'
import { Button, CardBody, Row, Col, FormGroup } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import moment from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DateTimePickerCustomize from '../../services/DateTimePickerCustomize'
import { isHearingUrgent } from '../../services'

const Time = ({ setExpedient, expedient }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [hearingDate, setHearingDate] = useState(null)
  const [isUrgentHearing, setIsUrgentHearing] = useState(false)
  const [hearingExtraPrice, setHearingExtraPrice] = useState(30)

  if (!expedient.judicial_party || expedient.hearing_type < 0) {
    navigate(`/create-expedient-form/judicial-party${location.search}`)
  }

  const validateCheckout = async (e, errors, values) => {
    if (errors.length > 0) {
      return toast.error('Por favor rellena los campos correctamente', {
        theme: 'colored',
      })
    }
    if (!hearingDate) {
      return toast.error('Por especifique la fecha del encargo', {
        theme: 'colored',
      })
    }
    setExpedient({
      ...expedient,
      hearing_date: hearingDate,
      is_urgent_hearing: isUrgentHearing,
      extra_urgent_price: hearingExtraPrice,
    })
    return navigate(`/create-expedient-form/information${location.search}`)
  }

  const onChangeHearingDate = (e) => {
    //If hearing date is tomorrow, and today is before 14, urgent_hearing is true
    //If hearing date is monday, and today is friday before 14, urgent_hearing is true
    const urgent_hearing = isHearingUrgent(e)

    setIsUrgentHearing(urgent_hearing)
    setHearingDate(e)
    urgent_hearing ? setHearingExtraPrice(30) : setHearingExtraPrice(0)
  }

  return (
    <CardBody>
      <div className="d-flex justify-content-center align-items-center">
        <h2 style={{ lineHeight: '1.5' }} className="col-md-6 text-center">
          ¡Genial! ¿Cuándo es el encargo?
        </h2>
      </div>
      <AvForm onSubmit={validateCheckout}>
        <Row className="d-flex justify-content-center align-items-center">
          <FormGroup className="col-md-12 d-flex justify-content-center align-items-center mt-md-4 mt-4 ">
            <DateTimePickerCustomize
              style={{ width: '325px' }}
              min={new Date(moment().add(1, 'day').startOf('day'))}
              onChange={(e) => onChangeHearingDate(e)}
              value={expedient.hearing_date}
            />
          </FormGroup>
          {isUrgentHearing && (
            <Row
              className="d-flex justify-content-center align-items-center mb-4"
              style={{ margin: '0' }}
            >
              <Col
                md="4"
                className="pb-2 pt-2 "
                style={{ border: '1px solid #42A4FF' }}
              >
                <em>
                  *Aviso: Estás solicitando un servicio urgente, lo cual implica
                  un recargo adicional. El coste total del encargo será de{' '}
                  {95 + hearingExtraPrice}€ + IVA (Detalle del coste: {95} € por
                  el servicio, {hearingExtraPrice}€ € por la urgencia). Por
                  favor, ten en cuenta que, debido a la proximidad de la fecha
                  del encargo, Legal Pigeon no puede asegurar la asignación de
                  un sustituto. Te confirmaremos la asignación del encargo a la
                  mayor brevedad posible.
                </em>

                <AvField
                  type="checkbox"
                  name="urgent_hearing_accepted"
                  label="Acepto el recargo de encargo urgente."
                  required
                  errorMessage="Marque la casilla"
                />
              </Col>
            </Row>
          )}
        </Row>
        <div className="d-flex flex-md-row flex-column justify-content-md-center align-items-md-center">
          <Button color="primary" size="lg">
            Siguiente
          </Button>
        </div>
      </AvForm>
    </CardBody>
  )
}

export default Time
