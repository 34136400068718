import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  selectedCommunityPartner: {},
  communityPartners: [],
  created: null,
  error: null,
}

export const communityPartners = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_COMMUNITY_PARTNER_REQUEST:
    case types.GET_COMMUNITY_PARTNERS_REQUEST:
    case types.CREATE_COMMUNITY_PARTNER_REQUEST:
    case types.UPDATE_COMMUNITY_PARTNER_REQUEST:
    case types.DELETE_COMMUNITY_PARTNER_REQUEST:
      return {
        ...state,
        error: null,
      }

    case types.GET_COMMUNITY_PARTNER_SUCCESS:
      return {
        ...state,
        selectedCommunityPartner: action.payload,
      }

    case types.GET_COMMUNITY_PARTNER_ERROR:
      toast.error('Ha ocurrido algún error al obtener el partner', {
        theme: 'colored',
      })
      return {
        ...state,
        error: action.payload,
      }

    case types.GET_COMMUNITY_PARTNERS_SUCCESS:
      return {
        ...state,
        communityPartners: action.payload,
      }

    case types.GET_COMMUNITY_PARTNERS_ERROR:
      toast.error('Ha ocurrido algún error al obtener los partners', {
        theme: 'colored',
      })
      return {
        ...state,
        error: action.payload,
      }

    case types.CREATE_COMMUNITY_PARTNER_SUCCESS:
      toast.success('Partner creado', { theme: 'colored' })
      return {
        ...state,
        created: action.payload.partner,
        communityPartners: [...state.communityPartners, action.payload.partner],
      }

    case types.CREATE_COMMUNITY_PARTNER_ERROR:
      toast.error('Ha ocurrido algún error al crear el partner', {
        theme: 'colored',
      })
      return {
        ...state,
        error: action.payload,
      }

    case types.UPDATE_COMMUNITY_PARTNER_SUCCESS:
      toast.success('Partner actualizado', { theme: 'colored' })
      return {
        ...state,
        communityPartners: state.communityPartners.map((partner) =>
          partner._id === action.payload.partner._id
            ? action.payload.partner
            : partner
        ),
      }

    case types.UPDATE_COMMUNITY_PARTNER_ERROR:
      toast.error('Ha ocurrido algún error al actualizar el partner', {
        theme: 'colored',
      })
      return {
        ...state,
        error: action.payload,
      }

    case types.DELETE_COMMUNITY_PARTNER_SUCCESS:
      toast.success('Partner eliminado')
      return {
        ...state,
        communityPartners: state.communityPartners.filter(
          (partner) => partner._id !== action.payload.id
        ),
      }

    case types.DELETE_COMMUNITY_PARTNER_ERROR:
      toast.error('Ha ocurrido algún error al eliminar el partner', {
        theme: 'colored',
      })
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}
