import React, { useState, useEffect } from 'react'
import { Button, CardBody, FormGroup, Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'
import { AvForm } from 'availity-reactstrap-validation'
import { substituteSkills } from '../../services/substituteSkills'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { createClientForm } from '../../redux/actions/users'
import { loginForm } from '../../redux/actions/auth'
import Loading from '../../components/common/Loading'
import action from '../../mixPanelsActions'
import { restartFormIfMissingValues } from './SkillsHandlers'
import { useLocation, useNavigate } from 'react-router'

const Skills = ({ setUser, user }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  restartFormIfMissingValues(navigate, user)

  const [skills, setSkills] = useState([])
  const [loading, setLoading] = useState(false)

  const activeUser = useSelector((state) => state.auth.activeUser)

  useEffect(() => {
    if (activeUser && activeUser._id) {
      const { _id, name, last_name, email, created_at } = activeUser
      const source = new URLSearchParams(location.search.replace('?', '')).get(
        'source'
      )

      action.identify(_id)
      action.people.set({
        $first_name: name,
        $last_name: last_name,
        $email: email,
        $created_at: created_at,
        $role: 'substitute',
        $is_substitute: true,
        $source: source || 'Organic',
      })
      action.track('SubstituteSignUp')

      dispatch(loginForm({ ...activeUser, password: user.password }))
      navigate('/thanks-substitute')
    }
  }, [activeUser, dispatch, user.password, navigate, location.search])

  const handleSkills = (skills) => {
    const substituteSkills = skills ? skills.map((skill) => skill.value) : null
    setUser({ ...user, substitute_skills: substituteSkills })
    setSkills(skills)
  }

  const validate = (e, errors, values) => {
    if (
      !user.substitute_skills ||
      user.substitute_skills === 'Seleccionar sus especialidades'
    ) {
      return toast.error('Por favor selecciona tus especialidades', {
        theme: 'colored',
      })
    }

    const maxSkillsLength = 5

    if (skills.length > maxSkillsLength) {
      return toast.error('Ha sobrepasado el máximo de especialidades', {
        theme: 'colored',
      })
    }

    setLoading(true)
    const source = new URLSearchParams(location.search.replace('?', '')).get(
      'source'
    )
    dispatch(createClientForm({ user: { ...user, source } }))
  }

  return (
    <CardBody>
      <Loading loading={loading} />
      <div className="d-flex justify-content-md-center align-items-md-center">
        <h2 style={{ lineHeight: '1.5' }} className="col-md-6 text-center">
          Por último, dinos tus especialidades.
          <br />
          <span>
            <br />
            <p style={{ fontSize: '14px' }}>
              Especialidades (multiselección, máx. 5)
            </p>
          </span>
        </h2>
      </div>
      <AvForm onSubmit={validate}>
        <Row className="d-flex justify-content-center align-items-center">
          <FormGroup className="col-md-3 d-md-flex justify-content-center m-md-4 mt-4 mb-n2">
            <Col md="10">
              <Select
                placeholder="Seleccione sus especialidades"
                name="substitute_skills"
                className="react-select-container mb-4"
                classNamePrefix="react-select"
                options={substituteSkills.map((i) => ({
                  value: i.id,
                  label: i.name,
                }))}
                isMulti
                onChange={(e) => handleSkills(e)}
                defaultValue={skills}
                key={`sk-${skills ? skills.length : 0}`}
              />
            </Col>
          </FormGroup>
        </Row>
        <div className="d-flex flex-md-row flex-column justify-content-md-center align-items-md-center">
          <Button color="primary" size="lg" disabled={skills.length === 0}>
            Siguiente
          </Button>
        </div>
      </AvForm>
    </CardBody>
  )
}

export default Skills
