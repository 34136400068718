import demandaReclamacionDeudaEntreParticularesOrdinario from './forms/demandaReclamacionDeudaEntreParticularesOrdinario'
import demandaReclamacionDeudaEntreParticularesVerbal from './forms/demandaReclamacionDeudaEntreParticularesVerbal'
import demandaDesahucio from './forms/demandaDesahucio'
import demandaEjecucionSentencia from './forms/demandaEjecucionSentencia'
import oposicionMonitorioMasSeisMil from './forms/oposicionMonitorioMasSeisMil'


const judicialBriefForms = [
  demandaReclamacionDeudaEntreParticularesOrdinario,
  demandaReclamacionDeudaEntreParticularesVerbal,
  demandaDesahucio,
  demandaEjecucionSentencia,
  oposicionMonitorioMasSeisMil
]

export default judicialBriefForms