const errorTypes = {
  DUPLICATE_EMAIL: 'Ya hay un usuario registrado con este email',
  PASSWORD_POLICY:
    'La contraseña no cumple con la política de seguridad: Debe contener caracteres especiales, una mayúscula, una minúscula y un número',
  LOCAL_STORAGE_NOT_FOUND: 'Error getting data from localStorage',
  LOCAL_STORAGE_NOT_SETTED: 'Error setting data from localStorage',
  LOCAL_STORAGE_NOT_REMOVED: 'Error removing data from localStorage',
  CREATE_USER_ERROR: 'No se ha podido crear el usuario, inténtalo de nuevo',
  GENERIC_ERROR:
    'Algo ha salido mal, inténtalo de nuevo o contacte con el administrador',
}

export default errorTypes
