export const getPermissionsToDeleteFile = ({
  documentType,
  expedient,
  procura,
  projectShortTerm,
  projectLongTerm,
  activeUser,
}) => {
  let currentDocument
  let statusToCheck

  switch (documentType) {
    case 'expedients':
      currentDocument = expedient
      statusToCheck = expedient?.status
      break
    case 'pigeonProcura':
      currentDocument = procura
      statusToCheck = procura?.status
      break
    case 'pigeonOnCall':
      currentDocument = projectShortTerm
      statusToCheck = projectShortTerm?.status
      break
    case 'pigeonInHouse':
      currentDocument = projectLongTerm
      statusToCheck = projectLongTerm?.status
      break
    default:
      currentDocument = null
      statusToCheck = null
  }

  const adminCanDelete = [
    'awaiting_acceptation',
    'celebration_pending',
  ].includes(statusToCheck)

  const clientAndCompanyCanDelete = ['awaiting_acceptation'].includes(
    statusToCheck
  )

  if (activeUser?.role === 'admin') {
    return adminCanDelete
  } else {
    return (
      (currentDocument?.creator_id?._id === activeUser?._id ||
        currentDocument?.company_id?._id === activeUser?._id) &&
      clientAndCompanyCanDelete
    )
  }
}
