import { judicialParties } from '../../services/judicialParties'
import { onChangeJudicialParty } from './JudicialPartyHandlers'

export const selectProps = (animatedComponents, setUser, setSelectedParties, user ) => ({
  className: "react-select-container",
  components: animatedComponents,
  isMulti: true,
  isClearable: true,
  max: 5,
  onChange: e => onChangeJudicialParty(e, setUser, setSelectedParties, user),
  options: judicialParties.map(i => ({ value: i.id, label: i.name })),
  placeholder: 'Seleccione el partido judicial'
})
