import React, { useState, useEffect } from 'react'
import { Button, CardBody, FormGroup, Row } from 'reactstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createClientForm } from '../../redux/actions/users'
import { loginForm } from '../../redux/actions/auth'
import Loading from '../../components/common/Loading'
import { toast } from 'react-toastify'
import action from '../../mixPanelsActions'

const Choose = ({ setUser, user }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const createdUser = useSelector((state) => state.auth.activeUser)

  useEffect(() => {
    if (
      !user.name ||
      !user.last_name ||
      !user.phone ||
      !user.email ||
      !user.password ||
      !user.checkbox
    ) {
      navigate(`/create-user-form/name${location.search}`)
    }
  }, [user, navigate, location.search])

  useEffect(() => {
    if (createdUser && createdUser._id) {
      const { _id, name, last_name, email, created_at } = createdUser
      action.identify(_id)
      action.people.set({
        $first_name: name,
        $last_name: last_name,
        $email: email,
        $created_at: created_at,
        $role: 'client',
        $is_substitute: false,
        $source: user.source || 'Organic',
      })
      action.track('ClientSignUp')

      dispatch(loginForm({ ...createdUser, password: user.password }))
      navigate('/thanks-client')
      setLoading(false)
    }
  }, [createdUser, dispatch, navigate, user.password, user.source])

  useEffect(() => {
    if (createdUser && !createdUser._id) {
      toast.error(
        'Este email ya está asociado a un usuario, si es tu email haz login',
        { theme: 'colored' }
      )
      navigate('/login')
      setLoading(false)
    }
  }, [createdUser, navigate])

  const substituteElection = () => {
    setUser({
      ...user,
      role: 'client',
      is_substitute: true,
      is_substitute_accepted: false,
      checkbox: true,
    })
    navigate(`/create-user-form/judicial-party${location.search}`)
  }

  const clientElection = () => {
    const source = new URLSearchParams(location.search.replace('?', '')).get(
      'source'
    )

    setUser({ ...user, role: 'client', source })
    setLoading(true)
    dispatch(
      createClientForm({
        user: { ...user, role: 'client', source },
      })
    )
  }

  return (
    <CardBody>
      <Loading loading={loading} />
      <div className="d-flex justify-content-center align-items-center">
        <h2
          style={{ lineHeight: '1.5' }}
          className="col-md-6 text-center"
        >{`${user.name}, ¿Qué buscas en Legal Pigeon?`}</h2>
      </div>
      <Row className="d-flex flex-column justify-content-center align-items-center">
        <FormGroup className="d-md-flex m-md-4 mt-n4">
          <Button color="success" size="lg" onClick={clientElection}>
            Contratar sustituciones
          </Button>
        </FormGroup>
        <FormGroup className="d-md-flex m-md-1 mt-n4">
          <Button color="primary" size="lg" onClick={substituteElection}>
            Ser abogado colaborador
          </Button>
        </FormGroup>
      </Row>
    </CardBody>
  )
}

export default Choose
