import React from 'react'

const ExpedientWizard = ({ step, activeUser }) => (
  <div style={{ margin: '3.5rem 0' }}>
    <div className="stepwizard mt-3 mb-4">
      <div className="stepwizard-row setup-panel mb-4">
        <div className="stepwizard-step">
          <a
            href="#step-1"
            type="button"
            className="btn btn-primary btn-circle"
          >
            1
          </a>
          <p>Seleccionar abogado</p>
        </div>
        <div className="stepwizard-step">
          <a
            href="#step-2"
            type="button"
            className="btn btn-primary btn-circle"
            disabled={step === 1}
          >
            2
          </a>
          <p>Descripción encargo</p>
        </div>
        <div className="stepwizard-step">
          <a
            href="#step-3"
            type="button"
            className="btn btn-primary btn-circle"
            disabled
          >
            3
          </a>
          <p>
            {!activeUser.role ||
            activeUser.role === 'client' ||
            activeUser.role === 'substitute'
              ? 'Pago'
              : 'Celebración'}
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default ExpedientWizard
