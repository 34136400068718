import errorTypes from '../../helpers/toastHelpers/errorTypes'
import { handleError } from '../../helpers/toastHelpers/handleError'

export const getItemFromLocalStorage = (key) => {
  try {
    return localStorage.getItem(key)
  } catch (error) {
    handleError(errorTypes.LOCAL_STORAGE_NOT_FOUND)
    return null
  }
}

export const setItemToLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, value)
  } catch (error) {
    handleError(errorTypes.LOCAL_STORAGE_NOT_SETTED)
  }
}

export const removeItemFromLocalStorage = (key) => {
  try {
    localStorage.removeItem(key)
  } catch (error) {
    handleError(errorTypes.LOCAL_STORAGE_NOT_REMOVED)
  }
}
