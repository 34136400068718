import React, { useState, useEffect, Fragment, useRef } from 'react'
import PageLayout from '../layouts/Page'
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
} from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { hearingTypeList } from '../services/hearingType'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { DateTimePicker } from 'react-widgets'
// import 'react-widgets/lib/scss/react-widgets.scss'
import moment from 'moment'
import { toast } from 'react-toastify'
import {
  createExpedient,
  resetCreatedExpedient,
  createSelfExpedient,
} from '../redux/actions/expedient'
import ExpedientWizard from '../components/ExpedientWizard'

import { baseAPI, STRIPE_KEY } from '../config'
import CheckoutModal from './CheckoutModal'
import { gtmEvent } from '../services/gtm-handler'
import { isHearingUrgent } from '../services'

import InvoiceAccountForm from '../components/forms/InvoiceAccountForm'
import { loadUserInvoiceAccount } from '../redux/actions/userInvoiceAccount'
import { loadUser } from '../redux/actions/users'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const stripePromise = loadStripe(STRIPE_KEY)
moment.locale('es')

const CreateExpedient = () => {
  const [showAlert, setShowAlert] = useState(false)
  // const [description, setDescription] = useState(null)
  const [hearingDate, setHearingDate] = useState(null)
  // const [minutaPrueba, setMinutaPrueba] = useState([])
  // const [instructa, setInstructa] = useState([])
  // const [trasladoJudicial, setTrasladoJudicial] = useState([])
  const [expedient, setExpedient] = useState({})
  const [showCheckoutModal, setShowCheckoutModal] = useState(false)
  const [checkoutIntent, setCheckoutIntent] = useState({})
  const [expedientFormat, setExpedientFormat] = useState('presencial')
  const [telematicSource, setTelematicSource] = useState('webex')
  const [telematicOther, setTelematicOther] = useState('')
  const [cards, setCards] = useState([])
  const [showInvoiceData, setShowInvoiceData] = useState(false)
  const [clientId, setClientId] = useState(null)
  const [isUrgentHearing, setIsUrgentHearing] = useState(false)
  const [colSize, setColSize] = useState('4')
  const [hearingExtraPrice, setHearingExtraPrice] = useState(0)
  const [useCustomFields, setUseCustomFields] = useState(false)
  const [customFields, setCustomFields] = useState(null)
  const [formDisabled, setFormDisabled] = useState(false)
  const telematicIDRef = useRef()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useSelector((state) => state.auth)
  const expedientState = useSelector((state) => state.expedient)
  const { userInvoiceAccounts } = useSelector(
    (state) => state.userInvoiceAccount
  )
  const users = useSelector((state) => state.users)
  const { activeUser } = auth
  const { user } = users

  useEffect(() => {
    if (activeUser.role !== 'admin') {
      dispatch(loadUserInvoiceAccount(activeUser._id))
    }

    if (activeUser.role === 'company' && activeUser.use_custom_fields) {
      setUseCustomFields(true)
      setCustomFields(activeUser.custom_fields)
    }

    if (
      activeUser.role === 'partner' &&
      activeUser.partner_parent.use_custom_fields
    ) {
      setUseCustomFields(true)
      setCustomFields(activeUser.partner_parent.custom_fields)
    }

    if (userInvoiceAccounts?.length > 0) {
      setShowInvoiceData(false)
    } else {
      setShowInvoiceData(true)
    }
  }, [activeUser, dispatch, userInvoiceAccounts])

  useEffect(() => {
    if (activeUser && !activeUser._id && !showAlert) {
      setShowAlert(true)
    }

    if (
      expedientState &&
      expedientState.created &&
      expedientState.created._id
    ) {
      dispatch(resetCreatedExpedient())
      navigate('/panel/expedients')
    }

    if (user && user !== users.user) {
      if (user.role === 'company' && user.use_custom_fields) {
        setUseCustomFields(true)
        setCustomFields(user.custom_fields)
      }
      if (user.role === 'partner' && user.partner_parent.use_custom_fields) {
        setUseCustomFields(true)
        setCustomFields(user.partner_parent.custom_fields)
      }
    }
  }, [
    activeUser,
    dispatch,
    expedientState,
    navigate,
    showAlert,
    user,
    users.user,
  ])

  // const updateDesc = (description) => setDescription(description)

  const updateDate = (hearingDate) => {
    const urgentHearing = isHearingUrgent(hearingDate)
    setIsUrgentHearing(urgentHearing)
    setHearingDate(hearingDate)

    if (activeUser.role === 'partner') {
      urgentHearing &&
        setHearingExtraPrice(
          activeUser.partner_parent.urgent_hearing_price || 30
        )
    } else if (activeUser.role === 'company') {
      urgentHearing &&
        setHearingExtraPrice(activeUser.urgent_hearing_extra_price || 30)
    } else if (activeUser.role === 'client') {
      urgentHearing &&
        setHearingExtraPrice(activeUser.urgent_hearing_extra_price || 30)
    }

    if (urgentHearing && activeUser.role === 'admin') {
      setColSize('3')
    } else {
      setColSize('4')
    }
  }

  const validateCheckout = (e, errors, expedient) => {
    if (errors.length > 0) {
      return toast.error('Por favor rellena los campos correctamente', {
        theme: 'colored',
      })
    }
    if (expedient.hearing_type === 'Seleccionar tipo de encargo') {
      return toast.error('Por favor selecciona el tipo de encargo', {
        theme: 'colored',
      })
    }
    if (expedient.dificulty === 'Seleccione la dificultad') {
      return toast.error('Por favor define la dificultad', { theme: 'colored' })
    }

    if (!hearingDate) {
      return toast.error('Por favor añade la fecha y hora de la encargo', {
        theme: 'colored',
      })
    }

    if (!expedient.resume || expedient.resume === '') {
      return toast.error('Por favor añade un resumen', { theme: 'colored' })
    }

    setFormDisabled(true)

    const searchParams = new URLSearchParams(location.search)

    const hearing_type = hearingTypeList.find(
      (h) => h.value === expedient.hearing_type
    )
    expedient.hearing_type = hearing_type.id

    expedient.judicial_party = searchParams.get('party')
    expedient.substitute_id = searchParams.get('substitute')
    expedient.status = 'awaiting_acceptation'
    expedient.description = null
    expedient.hearing_date = hearingDate
    expedient.minuta_prueba = []
    expedient.instructa = []
    expedient.traslado_judicial = []
    expedient.is_urgent_hearing = isUrgentHearing

    if (expedientFormat === 'telematica') {
      expedient.telematic = {
        tech: telematicSource,
      }

      if (telematicSource === 'webex' || telematicSource === 'zoom') {
        expedient.telematic.telematicID = telematicIDRef.current.value
      }
      if (telematicSource === 'other') {
        expedient.telematic.telematicOther = telematicOther
      }
    }

    if (activeUser.role === 'admin') {
      expedient.payment_status = 'awaiting_invoicing'
      return dispatch(createExpedient({ expedient })).then((res) => {
        const newExpedient = res.payload.expedient

        gtmEvent({
          event: 'purchase',
          ecommerce: {
            currencyCode: 'EUR',
            purchase: {
              actionField: {
                id: newExpedient._id, // id de transacción
                revenue: newExpedient.price, // Total
                tax: newExpedient.price * 0.21, // Impuestos, si aplica
                creatorId: newExpedient.creator_id, // id del creador
              },
              products: isUrgentHearing
                ? [
                    {
                      id: 'VA',
                      name: 'Vista no prepagada flow antiguo',
                      price:
                        newExpedient.price - newExpedient.extra_urgent_price,
                      quantity: 1,
                    },
                    {
                      id: 'VU',
                      name: 'Vista urgente',
                      price: newExpedient.extra_urgent_price,
                      quantity: 1,
                    },
                  ]
                : [
                    {
                      id: 'VA',
                      name: 'Vista no prepagada flow antiguo',
                      price: newExpedient.price,
                      quantity: 1,
                    },
                  ],
            },
          },
        })

        gtmEvent({
          event: 'purchase',
          ecommerce: {
            transaction_id: newExpedient._id,
            value: newExpedient.price,
            tax: newExpedient.price * 0.21,
            currency: 'EUR',
            creatorId: newExpedient.creator_id, // id del creador
            items: isUrgentHearing
              ? [
                  {
                    item_name: "Vista no prepagada flow antiguo'",
                    item_id: 'VA',
                    price: newExpedient.price - newExpedient.extra_urgent_price,
                    currency: 'EUR',
                    quantity: 1,
                  },
                  {
                    item_name: 'Vista urgente',
                    item_id: 'VU',
                    price: newExpedient.extra_urgent_price,
                    currency: 'EUR',
                    quantity: 1,
                  },
                ]
              : [
                  {
                    item_name: 'Vista no prepagada flow antiguo',
                    item_id: 'VA',
                    price: newExpedient.price,
                    currency: 'EUR',
                    quantity: 1,
                  },
                ],
          },
        })
      })
    }

    expedient.creator_id = activeUser._id

    if (activeUser._id === expedient.substitute_id) {
      expedient.payment_status = 'self'
      expedient.resume = 'Self expedient'
      expedient.status = 'celebration_pending'
      return dispatch(createSelfExpedient({ expedient }))
    }

    if (
      activeUser &&
      (activeUser.role === 'partner' || activeUser.role === 'company')
    ) {
      expedient.payment_status = 'awaiting_invoicing'
      expedient.company_id =
        activeUser.role === 'company'
          ? activeUser._id
          : activeUser.partner_parent

      return dispatch(createExpedient({ expedient })).then((res) => {
        const newExpedient = res.payload.expedient

        gtmEvent({
          event: 'purchase',
          ecommerce: {
            currencyCode: 'EUR',
            purchase: {
              actionField: {
                id: newExpedient._id, // id de transacción
                revenue: newExpedient.price, // Total
                tax: newExpedient.price * 0.21, // Impuestos, si aplica
                creatorId: newExpedient.creator_id, // id del creador
              },
              products: isUrgentHearing
                ? [
                    {
                      id: 'VA',
                      name: 'Vista no prepagada flow antiguo',
                      price:
                        newExpedient.price - newExpedient.extra_urgent_price,
                      quantity: 1,
                    },
                    {
                      id: 'VU',
                      name: 'Vista urgente flow antiguo',
                      price: newExpedient.extra_urgent_price,
                      quantity: 1,
                    },
                  ]
                : [
                    {
                      id: 'VA',
                      name: 'Vista no prepagada flow antiguo',
                      price: newExpedient.price,
                      quantity: 1,
                    },
                  ],
            },
          },
        })

        gtmEvent({
          event: 'purchase',
          ecommerce: {
            transaction_id: newExpedient._id,
            value: newExpedient.price,
            tax: newExpedient.price * 0.21,
            currency: 'EUR',
            creatorId: newExpedient.creator_id, // id del creador
            items: isUrgentHearing
              ? [
                  {
                    item_name: "Vista no prepagada flow antiguo'",
                    item_id: 'VA',
                    price: newExpedient.price - newExpedient.extra_urgent_price,
                    currency: 'EUR',
                    quantity: 1,
                  },
                  {
                    item_name: 'Vista urgente',
                    item_id: 'VU',
                    price: newExpedient.extra_urgent_price,
                    currency: 'EUR',
                    quantity: 1,
                  },
                ]
              : [
                  {
                    item_name: 'Vista no prepagada flow antiguo',
                    item_id: 'VA',
                    price: newExpedient.price,
                    currency: 'EUR',
                    quantity: 1,
                  },
                ],
          },
        })
      })
    }
    setFormDisabled(false)
    setExpedient(expedient)
    checkout()
  }

  const checkout = async () => {
    setFormDisabled(true)
    const res = await fetch(`${baseAPI}/checkout-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ user_id: activeUser._id }),
    })
      .then(async (res) => {
        if (res.status === 200) {
          return await res.json()
        }
      })
      .catch(() => false)

    if (res) {
      setShowCheckoutModal(true)
      setCheckoutIntent(res.token)
      setCards(res.cards)
    }

    setFormDisabled(false)
  }

  const closeCheckoutModal = () => {
    setFormDisabled(false)
    setShowCheckoutModal(false)
  }

  const handleCreateExpedient = (payment_data) => {
    setFormDisabled(true)
    setShowCheckoutModal(false)

    const newExpedient = {
      ...expedient,
      payment_data,
    }

    dispatch(createExpedient({ expedient: newExpedient }))
    setFormDisabled(false)
  }

  const updateFormat = (e) => setExpedientFormat(e.target.value)
  const updateTelematicSource = (e) => setTelematicSource(e.target.value)
  const updateTelematicOther = (e) => setTelematicOther(e.target.value)

  const handleChangeCreatorId = (e) => {
    dispatch(loadUser({ id: e.target.value }))
    dispatch(loadUserInvoiceAccount(e.target.value))
    setClientId(e.target.value)
  }

  const { search } = location
  const searchParams = new URLSearchParams(search)
  const pay =
    (activeUser.role === 'client' || activeUser.role === 'substitute') &&
    searchParams.get('name') !== 'self'

  const canSeePrice =
    activeUser.role === 'partner' &&
    activeUser.hasOwnProperty('partner_price_view') &&
    !activeUser.partner_price_view
      ? false
      : true

  const blockInvoiceCreation = !(
    activeUser.role === 'admin' ||
    (activeUser.role === 'company' &&
      !activeUser.block_invoice_account_creation) ||
    (activeUser.role === 'partner' &&
      !activeUser.partner_parent?.block_invoice_account_creation)
  )

  return (
    <PageLayout className="text-center" lightHeader>
      <h1 className="text-center mt-3 mb-4">Descripción del encargo</h1>

      <Card className="mt-3">
        {searchParams && searchParams.get('name') !== 'self' && (
          <CardHeader>
            <ExpedientWizard step={3} activeUser={activeUser} />
          </CardHeader>
        )}

        <CardBody>
          <div
            className={`alert alert-danger alert-dismissible fade mb-4 text-center ${
              showAlert ? 'show' : 'hide'
            }`}
            role="alert"
          >
            <div className="alert-message">
              <div style={{ fontSize: '1.2rem' }}>
                No se puede crear un encargo sin usuario activo.{' '}
                <Link to="/" className="ms-2 btn btn-primary">
                  Volver al inicio
                </Link>
              </div>
            </div>
          </div>

          <AvForm onSubmit={validateCheckout} disabled={formDisabled}>
            {activeUser.role === 'admin' && (
              <div className="mb-4">
                <Row>
                  <Col md="4">
                    <AvField
                      name="creator_id"
                      label="ID cliente"
                      onBlur={handleChangeCreatorId}
                    />
                  </Col>
                </Row>
              </div>
            )}

            {searchParams && searchParams.get('name') !== 'self' && (
              <h3 className="mb-4 mt-2">
                Abogado colaborador: {searchParams.get('name')}
              </h3>
            )}

            <h3 className="blue2 mb-3 mt-4">Detalles del encargo</h3>
            <Row>
              <Col md={colSize}>
                <AvField
                  name="nono"
                  disabled
                  label="Partido judicial"
                  value={searchParams.get('party_label')}
                />
              </Col>
              <Col md={colSize}>
                <AvField
                  name="court"
                  required
                  label="Número de juzgado"
                  errorMessage="Introduzca el número de juzgado"
                />
              </Col>
              <Col md={colSize}>
                <Label>Fecha y hora del encargo</Label>
                <DateTimePicker
                  min={
                    activeUser && activeUser.role === 'admin'
                      ? ''
                      : new Date(moment().add(1, 'day').startOf('day'))
                  }
                  required
                  placeholder={'Click en los iconos -> (fecha y hora)'}
                  format={'[Dia:] DD/MM/YYYY  [Hora:] hh:mm A'}
                  step={1}
                  culture="es"
                  onChange={updateDate}
                />
              </Col>
              {isUrgentHearing && activeUser.role === 'admin' && (
                <Col md={colSize}>
                  <AvField
                    name="extra_urgent_price"
                    label="Recargo por encargo urgente (€)"
                    type="number"
                    defaultValue={30}
                    onChange={(e) => setHearingExtraPrice(e.target.value)}
                  />
                </Col>
              )}
            </Row>

            <h3 className="blue2 mb-3 mt-4">Características</h3>
            <Row className="mb-3">
              <Col md="4">
                <AvField
                  name="client_internal_reference"
                  label="Referencia interna (opcional)"
                />
              </Col>

              <Col md="4">
                <AvField
                  required
                  label="Tipo de encargo"
                  type="select"
                  name="hearing_type"
                  errorMessage="Seleccione el tipo de encargo"
                >
                  <option>Seleccionar tipo de encargo</option>
                  {hearingTypeList
                    .filter((t) => t.type === 'hearing')
                    .map((t) => (
                      <option key={t.value}>{t.value}</option>
                    ))}
                </AvField>
              </Col>

              <Col md="4">
                <AvField
                  required
                  label="Dificultad (1 fácil, 5 difícil)"
                  type="select"
                  name="dificulty"
                  errorMessage="Seleccione la dificultad"
                >
                  <option>Seleccionar dificultad</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </AvField>
              </Col>
            </Row>

            {searchParams && searchParams.get('name') === 'self' && (
              <Fragment>
                <h3 className="blue2 mb-3 mt-4">Formato</h3>
                <Row className="mb-3">
                  <Col md="4">
                    <FormGroup tag="fieldset">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="formato"
                            value="presencial"
                            defaultChecked
                            onChange={updateFormat}
                          />{' '}
                          Presencial
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="formato"
                            value="telematica"
                            onChange={updateFormat}
                          />{' '}
                          Telemática
                        </Label>
                      </FormGroup>
                    </FormGroup>

                    {expedientFormat === 'telematica' && (
                      <Fragment>
                        <FormGroup>
                          <Label className="h4">Proveedor de tecnología:</Label>
                          <select
                            className="form-control"
                            onChange={updateTelematicSource}
                            value={telematicSource}
                          >
                            <option value="webex">Cisco Webex</option>
                            <option value="zoom">Zoom</option>
                            <option value="other">Otros</option>
                          </select>
                        </FormGroup>

                        {(telematicSource === 'webex' ||
                          telematicSource === 'zoom') && (
                          <div>
                            <FormGroup>
                              <Label for="exampleEmail">
                                Url de la reunión:{' '}
                              </Label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="123 456..."
                                ref={telematicIDRef}
                              />
                            </FormGroup>
                          </div>
                        )}
                        {telematicSource === 'other' && (
                          <textarea
                            cols="50"
                            rows="6"
                            placeholder="Información y/o links de la reunión"
                            onChange={updateTelematicOther}
                          ></textarea>
                        )}
                      </Fragment>
                    )}
                  </Col>
                </Row>
              </Fragment>
            )}

            {((activeUser.role === 'admin' && useCustomFields) ||
              (activeUser.role === 'company' && activeUser.use_custom_fields) ||
              (activeUser.role === 'partner' &&
                activeUser.partner_parent.use_custom_fields) ||
              (user && user.role === 'company' && user.use_custom_fields) ||
              (user &&
                user.role === 'partner' &&
                user.partner_parent.use_custom_fields)) &&
              customFields && (
                <Fragment>
                  <h3 className="blue2 mb-3 mt-4"> Campos personalizados </h3>
                  <Row>
                    {Object.keys(customFields).length > 0 &&
                      Object.keys(customFields).map((fieldId) => (
                        <Fragment key={customFields[fieldId].id}>
                          {customFields[fieldId].active && (
                            <Col xs="12" md="6">
                              {customFields[fieldId].type === 'String' && (
                                <AvField
                                  name={`custom_fields[${customFields[fieldId].id}]`}
                                  label={customFields[fieldId].name}
                                  type="text"
                                  required={customFields[fieldId].required}
                                  validate={{
                                    pattern: { value: '/\\w.*/gm' },
                                  }}
                                  errorMessage="Rellene correctamente el campo"
                                />
                              )}
                              {customFields[fieldId].type === 'Boolean' && (
                                <AvField
                                  name={`custom_fields[${customFields[fieldId].id}]`}
                                  label={customFields[fieldId].name}
                                  type="select"
                                  required={customFields[fieldId].required}
                                  errorMessage="Seleccione una opción"
                                >
                                  <option value={null}>
                                    {' '}
                                    Seleccionar opción{' '}
                                  </option>
                                  <option>Sí</option>
                                  <option>No</option>
                                </AvField>
                              )}
                              {customFields[fieldId].type === 'Selector' && (
                                <AvField
                                  name={`custom_fields[${customFields[fieldId].id}]`}
                                  label={customFields[fieldId].name}
                                  type="select"
                                  required={customFields[fieldId].required}
                                  errorMessage="Seleccione una opción"
                                >
                                  <option value={null}>
                                    {' '}
                                    Seleccionar opción{' '}
                                  </option>
                                  {customFields[fieldId].options
                                    .split(',')
                                    .map((option) => (
                                      <option value={option}> {option}</option>
                                    ))}
                                </AvField>
                              )}
                            </Col>
                          )}
                        </Fragment>
                      ))}
                  </Row>
                </Fragment>
              )}

            <h3 className="blue2 mb-3 mt-4">Resumen y descripción</h3>
            {searchParams && searchParams.get('name') !== 'self' && (
              <AvField
                name="resume"
                label="Resumen (1 línea). Explica brevemente de qué trata el encargo. Ayudará al sustituto a identificar y aceptar el encargo"
                required
                errorMessage="Explica brevemente de qué trata el asunto"
              />
            )}

            {isUrgentHearing &&
              hearingExtraPrice > 0 &&
              activeUser.role !== 'admin' && (
                <Row className="mb-3 mt-3" style={{ margin: '0' }}>
                  <Col
                    className="pb-2 pt-2"
                    style={{ border: '1px solid #42A4FF' }}
                  >
                    <em>
                      {canSeePrice ? (
                        <Fragment>
                          *Aviso: Estás contratando un encargo urgente que tiene
                          recargo. El coste total del encargo será{' '}
                          {activeUser.default_expedient_price +
                            hearingExtraPrice}
                          € + IVA. Coste del encargo:{' '}
                          {activeUser.default_expedient_price}€, Coste de la
                          urgencia: {hearingExtraPrice}€.
                        </Fragment>
                      ) : (
                        <Fragment>
                          *Aviso: Estás contratando un encargo urgente, tiene un
                          recargo de {hearingExtraPrice}€.
                        </Fragment>
                      )}
                    </em>
                    <AvField
                      type="checkbox"
                      name="urgent_hearing_accepted"
                      label="Acepto el recargo de encargo urgente."
                      required
                      errorMessage="Marque la casilla"
                    />
                  </Col>
                </Row>
              )}

            <Fragment>
              <div className="d-flex mt-4">
                <div className="flex-grow-1">
                  <h3 className="mt-0 mb-4 d-flex align-items-center border-bottom pb-2 text-primary">
                    Datos de facturación
                  </h3>
                </div>
              </div>
              <Row>
                <Col md="4">
                  <AvField
                    required={true}
                    name="user_invoice_account_id"
                    label="Datos de facturación"
                    type="select"
                    errorMessage="Seleccione datos de facturación"
                    value={
                      userInvoiceAccounts.length > 0
                        ? userInvoiceAccounts[0]._id
                        : ''
                    }
                  >
                    <option> Seleccionar datos </option>
                    {userInvoiceAccounts.map((data, index) => (
                      <option key={index} value={data._id}>
                        {data.cif} - {data.invoice_name}{' '}
                        {data.notes !== '' && ' - ' + data.notes.substr(0, 15)}
                      </option>
                    ))}
                  </AvField>
                </Col>
                {!blockInvoiceCreation && (
                  <Col
                    xs="12"
                    className="pb-1 pt-1 mt-0 pointer text-secondary"
                    onClick={() => setShowInvoiceData(!showInvoiceData)}
                  >
                    <em key="new_invoice_account">
                      *Si quieres añadir una cuenta nueva de facturación, clica
                      aquí.
                    </em>
                  </Col>
                )}
              </Row>
            </Fragment>
            {!showInvoiceData &&
              (pay ? (
                <button
                  id="oldFlowClickNextStep1"
                  className="btn btn-success btn-lg"
                >
                  {' '}
                  Siguiente{' '}
                </button>
              ) : (
                <button
                  id="oldFlowCreateHearingAdminCompanyPartner"
                  className="btn btn-success btn-lg"
                >
                  {' '}
                  Crear Encargo{' '}
                </button>
              ))}
          </AvForm>

          {showInvoiceData && (
            <Fragment>
              <hr />
              <InvoiceAccountForm
                id="invoice-account-form"
                showFormActions={true}
                client_id={clientId}
                onSubmit={() => setShowInvoiceData(!showInvoiceData)}
              />
            </Fragment>
          )}
        </CardBody>
      </Card>
      <Elements locale="es" stripe={stripePromise}>
        <CheckoutModal
          show={showCheckoutModal}
          checkoutIntent={checkoutIntent}
          createExpedient={handleCreateExpedient}
          closeCheckoutModal={closeCheckoutModal}
          cards={cards}
          isUrgentHearing={isUrgentHearing}
          price={activeUser.default_expedient_price}
        />
      </Elements>
    </PageLayout>
  )
}

export default CreateExpedient
