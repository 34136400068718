import React, { useEffect } from 'react'
import { CardBody, Button } from 'reactstrap'
import { useNavigate } from 'react-router-dom'

const LastStep = ({ user, expedient }) => {
  const navigate = useNavigate()

  //eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setTimeout(function () {
      navigate('/panel/expedients')
    }, 6000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    !expedient.judicial_party ||
    expedient.hearing_type < 0 ||
    !expedient.hearing_date ||
    !expedient.dificulty ||
    !expedient.court ||
    !expedient.resume ||
    !user.name ||
    !user.last_name
  ) {
    navigate('/create-expedient-form/name')
  }

  return (
    <CardBody>
      <div className=" d-flex justify-content-center align-items-center flex-column">
        <h2 style={{ lineHeight: '1.5' }} className="col-lg-6 text-center">
          ¡Enhorabuena!
        </h2>
        <h2 style={{ lineHeight: '1.5' }} className="col-lg-6 text-center">
          Tu petición se ha realizado con éxito, ya lo tenemos todo para
          gestionar tu encargo con la mejor garantía
        </h2>
        <h2 style={{ lineHeight: '1.5' }} className="col-lg-6 text-center">
          ¡Nos ponemos a ello!
        </h2>
        <Button
          onClick={() => navigate('/panel/expedients')}
          color="primary"
          size="lg"
        >
          Ver encargo
        </Button>
      </div>
    </CardBody>
  )
}

export default LastStep
